import React, { useContext } from 'react';
import { Redirect } from "react-router-dom";
import * as webStorageService from '../Utility/WebStorageFactory';
import { locale, MetaTags } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
import dataLayers from '../Utility/DataLayers';
import Button from '../Partials/Button';
const Limit = () => {
    const { translations } = useContext(JsonContext);

    let pageState = webStorageService.getSessionData('createPageState');
    // pageState = 'Limit';
    if (pageState == '' || pageState == undefined || pageState == null) {
        window.location = '/' + locale + '/Home';
    } else if (pageState != 'Limit') {
        return <Redirect to={'/' + locale + '/' + pageState} />
    }

    document.querySelector('body').setAttribute('pagename', 'Limit');
    const _intervalG = setInterval(function () {
        logout();
    }, 300);
    function logout() {
        if (window.gigya != undefined) {
            window.gigya.accounts.logout();
            clearInterval(_intervalG);
        }
    }
    let globalLimit = webStorageService.getSessionData('limitType'),
        limitPageHeading,
        limitPageDesc,
        limitPageDesc1,
        limitPageDesc2;
    // globalLimit = 'MaxUserAttemptLimit';
    limitPageHeading = translations.Sorry;
    switch (globalLimit) {
     
        case 'GlobalRedemptionLimit':
            dataLayers().winLimit();
            limitPageDesc = '';
            limitPageDesc1 = translations.WinLimit;
            break;
     
        case 'MaxUserAttemptLimit':
            limitPageHeading = '';
            limitPageDesc = '';
            limitPageDesc1 = translations.CoreKelloggs_MaximumEntryLimit;
            limitPageDesc2 = translations.CoreKelloggs_MaximumEntryLimit_teamPringles;
            break;
        default:
            dataLayers().entryLimit();
            limitPageDesc = translations.DailyLimit;
            limitPageDesc1 = translations.DailyLimitDescription + ' ' + translations.DailyLimitDescription1 ;
    }

    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_LimitPageTitle + ' | ' + translations.CampaignName}
                description={translations.MetaContent}
                keywords=''
            />
            <section className="limit-page">
                {limitPageHeading && <h1>{limitPageHeading}</h1>}
                {limitPageDesc && <h2 role="alert" dangerouslySetInnerHTML={{ __html: limitPageDesc }}></h2>}
                {limitPageDesc1 && <p role="alert" dangerouslySetInnerHTML={{ __html: limitPageDesc1 }}></p>}
                {limitPageDesc2 && <p role="alert" dangerouslySetInnerHTML={{ __html: limitPageDesc2 }}></p>}
                <div className="cta-container">
                <Button />
                </div>
            </section>
        </>
    )
}

export default Limit;