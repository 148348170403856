import { useHistory } from "react-router-dom";
import { useContext } from 'react';
import { locale, $, pageScroll, isNpr } from './Global';
import * as API from './ApiCalls';
import * as webStorageService from './WebStorageFactory';
import JsonContext from './JsonProvider';
import ErrorProvider from "./ErrorProvider";
import dataLayers from "./DataLayers";

const LoadGRM = (formType, cb) => {
    const { settings } = useContext(JsonContext);
    const history = useHistory();
    const { HandleError } = ErrorProvider();
    const handleRoute = (path) => {
        history.push({ pathname: path });
        pageScroll();
    };
    const handleRouteNpn = (path, obj) => {
        if(path.toLowerCase().indexOf('thankyou')>-1){
            webStorageService.setSessionData('createPageState', 'Thankyou');
        }else if(path.toLowerCase().indexOf('puzzle')>-1){
            webStorageService.setSessionData('createPageState', 'Puzzle');
        }
        history.push({ pathname: path, state: obj });
        pageScroll();
    };
    var login = false,
        register = false,
        subscription = false,
        reset = false,
        field_type,
        category,
        category_body,
        questionLegal;

    switch (formType) {
        case 'login':
            login = true;
            register = false;
            break;
        case 'register':
            login = false;
            register = true;
            break;
        case 'forgot':
            login = true;
            register = false;
            break;
        case 'reset':
            // login = true;
            // register = false;
            reset = true;
            break;
        default:
            login = true;
            register = true;
    }
    var generateGRM = function () {
        if (document.querySelectorAll('#reg-container').length) {
            var container = document.getElementById('reg-container'),
                url = window.location.host,
                socialLogin = (settings.SocialSharingLocales.toLowerCase()).indexOf(locale.toLowerCase()) > -1 ? true : false,
                configObj = {
                    environment: settings.GRMServiceURL.match('stage') ? 'stage' : 'prod', // or 'stage' for testing
                    moduleKey: settings.GRMModuleKey, //modulekeyvalue, //
                    showLogin: login,
                    showSignup: register,
                    showFacebook: socialLogin,
                    showTwitter: socialLogin,
                    facebookAppId: window.location.hostname.indexOf('kelloggs.com') < 0 ? '293589412691589' : '3076416759292148',
                    languageParam : locale.split("_").join("-"),
                    region:"KEU",
                    gigyaApiKey : settings.SAPGigyaApiKey,
                    // myAccountUrl: '/path/to/myaccount.html', // required, if you are implementing my account v6
                    onAuth: function (authUser) {
                        // cookie authUser properties, or post them back to your server via AJAX
                        // then fire callback to notify the reg v6 module that your auth routine has finished
                        // for example:
                        if (authUser) {
                            if (login || register) {
                                let NbaConsent=$('#KSTL-Registration-wrapper-Nba23Consent div input').is(":checked");
                                
                                if (window.location.search.length) {
                                    field_type = window.location.search.split('utm_term=')[1];
                                    if (field_type == "allcategories") {
                                        category = window.location.search.split('&utm_content=')[1].split('&')[0];

                                    }
                                    else {
                                        category = field_type;
                                    }
                                    }
                                else {
                                    category = "NA";
                                }
                               
                                var dataToSend = {
                                    // 'redemption-token': webStorageService.getSessionData('servToken'),
                                    'QuestionLegal': questionLegal,
                                    'accessToken': authUser.AccessToken,
                                    'userName': authUser.UsernameForServiceAuth,
                                    'profileId': authUser.ProfileID,
                                    'userAgent': navigator.userAgent,
                                    'allParamsEncrypted': authUser.AllParamsEncrypted,
                                    'NbaConsent': NbaConsent
                                };
                                // $rootScope.profileId = authUser.ProfileID;
                                // $rootScope.disableRegister = true;


                                API.checkUser(dataToSend).then((response) => {
                                    // var locale = webStorageService.getSessionData('requestedlocale'),
                                    var page = '',
                                        proceed = true;
                                    if (response.redirect) {

                                        proceed = false;
                                       
                                        if (response.redirect.indexOf('limit') > -1) {
                                            webStorageService.setSessionData('createPageState', 'Limit');
                                            webStorageService.setSessionData('limitType', response.message);
                                        }
                                        if (response.redirect.indexOf('puzzle') > -1) {
                                            webStorageService.setSessionData('createPageState', 'puzzle');
                                        }
                                        handleRoute(response.redirect);

                                    }
                                    if (response.user) {
                                        response.user.profileId = authUser.ProfileID;
                                        // resourceService.setGRMUser(response.user);
                                        webStorageService.setSessionData('grmUser', response.user);
                                    }
                                    if (response.token) {
                                        webStorageService.setSessionData('servToken', response.token);
                                        var token = response.token;
                                    }
                                    if (formType === 'login') {
                                        dataLayers().signInData(authUser.ProfileID, authUser.City, authUser.Country, subscription, authUser.Flow);
                                    }
                                    if (formType === 'register') {
                                        dataLayers().registerData(authUser.ProfileID, authUser.City, authUser.Country, subscription, authUser.Flow);
                                    }
                                    if ((formType === 'login' || formType === 'register') && proceed) {
                                        
                                        if (isNpr && response.Nobatchcoderequired) {
                                            var data = [];
                                            API.checkUpcCodes(data, webStorageService.getSessionData('servToken')).then(function (response) {
                                                    
                                                    if (!!response.result && response.result.length > 0) {
                                                        webStorageService.setSessionData('servToken', response.token);
                                                        var grm = webStorageService.getSessionData('grmUser');
                                                        var shippingInfoObj = {
                                                            'redemption-token': webStorageService.getSessionData('servToken'),
                                                            'shippingDetails': grm
                                                        };
                                                        API.orderAward(shippingInfoObj).then(function (response) {
                                                    
                                                            
                                                            if (response.token) {
                                                              
                                                                webStorageService.setSessionData('servToken', response.token);
                                                                webStorageService.setLocalData('servToken', response.token);
                                                            } else {
                                                                webStorageService.removeSessionData('servToken');
                                                            }
                                                            // var grmUser = {};
                                                            // if (Object.keys(resourceService.getGRMUser()).length !== 0) {
                                                            //     grmUser = resourceService.getGRMUser();
                                                            // } else {
                                                            //     grmUser = webStorageService.getSessionData('grmUser');
                                                            // }
                                                            if (response.redirect) {
                                                                var winnerObj = {};
                                                                if (response.redirect.indexOf('puzzle') > -1) {
                                                                    webStorageService.setSessionData('createPageState', 'Puzzle');
                                                                }
                                                                if (response.redirect.toLowerCase().indexOf('thankyou') > -1) {
                                                                    if (response.Winner !== undefined && response.Winner !== null) {
                                                                        winnerObj = {
                                                                            winner: response.Winner,
                                                                            isExtendedPhase: response.ExtendedPhase,
                                                                            refCode: response.RefCode
                                                                        }
                                                                            dataLayers().orderData(response.Winner);
                                                                    }
                                                                }
                                                              
                                                                if (response.redirect.indexOf('limit') > -1) {
                                                                    webStorageService.setSessionData('createPageState', 'Limit');
                                                                }
                                                                handleRouteNpn(response.redirect, winnerObj);
                                                                
                                                            } else {
                                                                HandleError();
                                                            }
                                                        }).catch(function (error) {
                                                            HandleError(error.response);
                                                        });
                                                    }
                                            }).catch(function (error) {
                                                HandleError(error.response);
                                            });
                                        } else {
                                            webStorageService.setSessionData('createPageState', 'Batchcodeentry');
                                            handleRoute("/" + locale + "/BatchCodeEntry");
                                        }
                                    }
                                }).catch(function (error) {
                                   HandleError(error.response);
                                });
                            } else if (reset) {
                                setTimeout(function () {
                                    window.location = '/' + locale + '/Home';
                                }, 4000);
                            }
                        }
                    },
                    onExit: function (authUser) {
                        // your redirect logic goes here                       
                        // NOTE: if you already used onAuth to authenticate the user
                        // then you don't need to do anything with authUser here
                        // console.log(authUser, 'exit');
                        // AccessService.clearPermissions();
                        // SpoonService.clearSpoon();
                        // ShippingService.clearData();
                        // $location.path(startAgainUrl);
                    }
                };
            function initReg() {
                // initialize the reg v6 module
                var regModule = window.KSTL.Registration.init(container, configObj);
                // subscribe to module event notifications
                var eventViewRender = regModule.bind('view:render', function (topic, view) {
                    cb();
                    if (view.name === '/Sweepstakes/Error') {
                        view.element.hidden = true;
                    }
                    // personal checkbox
                    $('#reg-container input[type="checkbox"] + label').prepend('<span></span>');

                    // placeholder
                    /*$('#reg-container input[name="FirstName"]').attr("placeholder", resourceService.translations.GrmFirstNamePlaceholder);
                    $('#reg-container input[name="LastName"]').attr("placeholder", resourceService.translations.GrmLastNamePlaceholder);
                    $('#reg-container input[name="Password"]').attr("placeholder", resourceService.translations.GrmPasswordPlaceholder);
                    $('#reg-container input[name="PasswordConfirm"]').attr("placeholder", resourceService.translations.GrmConfirmPasswordPlaceholder);
                    $('#reg-container input[name="AddressLine1"]').attr("placeholder", resourceService.translations.GrmAddressLine1Placeholder);
                    $('#reg-container input[name="AddressLine2"]').attr("placeholder", resourceService.translations.GrmAddressLine2Placeholder);
                    $('#reg-container input[name="City"]').attr("placeholder", resourceService.translations.GrmTownCityPlaceholder);
                    $('#reg-container input[name="PostalCode"]').attr("placeholder", resourceService.translations.GrmPostcodePlaceholder);
                    $('#reg-container input[name="UserName"]').attr("placeholder", resourceService.translations.GrmEmailPlaceholder);
                    $('#reg-container input[name="UserNameConfirm"]').attr("placeholder", resourceService.translations.GrmConfirmEmailPlaceholder);
                    $('#reg-container input[name="PhoneNumber1"]').attr("placeholder", resourceService.translations.GrmPhoneNumberPlaceholder);
                    */

                    $('#KSTL-Registration-wrapper-TermsConditions label a').attr("target", "_self");

                    $('#KSTL-Registration-wrapper-HeaderSubscription input').on('change', function () {
                        subscription = $(this).prop("checked");
                    });                   

                    
                    $('#KSTL-Registration-QuestionLegal').on('change', function () {
                        questionLegal = $('#KSTL-Registration-QuestionLegal').val().trim();
                        $('#KSTL-Registration-QuestionLegal').val(questionLegal);
                    });

                    var $input = $('#KSTL-Registration-PostalCode');
                    $input.on('keyup', (function (e) {
                        var max = $input[0] ? $input[0].maxLength : 10;
                        if (max) {
                            if ($input.val().length > max) {
                                $input.val($input.val().substr(0, max));
                            }
                        }
                    }));
                    // set column height
                    var thises = $('div[ng-activity-indicator="Circled"]');
                    if (thises.hasClass("ng-hide")) {
                        var windowWidth = window.innerWidth;
                        var heightss = "";
                        var firstcol = $('.js-setheight .twocols > div:first-child .content');
                        var secondcol = $('.js-setheight .twocols > div:last-child .content');
                        if (windowWidth > 768) {
                            firstcol.css({ "height": "" });
                            secondcol.css({ 'height': "" });
                            heightss = Math.max(firstcol.outerHeight(true), secondcol.outerHeight(true));
                            firstcol.css({ 'height': heightss });
                            secondcol.css({ 'height': heightss });
                        }
                    }

                    // change position of validate-error
                    $(".ns-popover-list-theme").each(function () {
                        var geterror = $(this);
                        var getfieldname = $(this).attr("id").slice(26);
                        var controlformid = "#KSTL-Registration-wrapper-" + getfieldname;
                        var getcontrolform = $(controlformid);
                        geterror.appendTo(getcontrolform);
                    });
                    // if ($('.pod').length > 0){                    
                    //     var $pod = $('.pod'),
                    //         grmScope = KSTL.Registration.frmElement($pod.get()).isolateScope();
                    // }
                    // if (!grmScope) {
                    // 	return;
                    // }
                });

                var eventFormSubmit = regModule.bind('form:submit', function (topic, form) {
                    /*console.log('regv6 form:submit', form.name);
                    if you wish to unsubscribe
                    regModule.unbind(eventViewRender);
                    console.log(eventFormSubmit);*/
                });

                var eventForgotPwdRender = regModule.bind('forgotpassword:render', function (topic, view) {
                    $(".login-container .login-heading").hide();
                });

                var eventTnCLinkRender = regModule.bind('registration:render', function (topic, view) {
                    if ($('#KSTL-Registration-AddressLine1').length) {
                        var addressVal = $('#KSTL-Registration-AddressLine1').val();
                        // if(addressVal==''|| addressVal==null || addressVal==undefined)
                        // window.KSTL.Registration.frmElement.AddressLine1 = '.';
                    }
                });
                const _intervalC = setInterval(function () {
                    if(settings.SAPGigyaApiKey!=undefined && settings.SAPGigyaApiKey!=null && settings.SAPGigyaApiKey!=''){
                    getEmailConsent();
                    }
                }, 300);
                function getEmailConsent() {
                    if($($('#register-site-login > div.tnc-reg')[1])!= null){
                        $($('#register-site-login > div.tnc-reg input')[1]).on('change', function () {
                            subscription = $(this).prop("checked");
                            clearInterval(_intervalC);
                        });
                     }
                }
            }
            clearInterval(_interval);
            // async script loader... do not modify
            return (function () {
                var src, kreg, s;
                if(settings.SAPGigyaApiKey!=undefined && settings.SAPGigyaApiKey!=null && settings.SAPGigyaApiKey!=''){
                    src = settings.GRMServiceURL + '/scripts/registrationv2.js';;
                }else{
                    src = settings.GRMServiceURL + '/scripts/registration.min.js';
                }
                kreg = document.createElement('script');
                kreg.type = 'text/javascript';
                kreg.onload = kreg.onreadystatechange = function () {
                    if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
                        kreg.onload = kreg.onreadystatechange = null;
                        if (container) {
                            initReg();
                        }
                    }
                };
                kreg.src = src;
                s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(kreg, s);
            })();
        }
    }
    var _interval = setInterval(function () {
        generateGRM();
    }, 300);
};

export default LoadGRM;
