import React, { useContext, useState } from 'react';
import { locale, MetaTags, $ } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
import { useLocation } from "react-router-dom";

const FAQ = () => {
    const { translations } = useContext(JsonContext);
    const location = useLocation();
    document.querySelector('body').setAttribute('pagename', 'FAQ');
    var pageScrollFaq = function () {
        window.scrollTo(0, 0);
        setTimeout(function () {
            $('html, body').animate({
                scrollTop: $('#faqPara').offset().top - 100
            }, 1000);
        }, 1000);
    }
    if (location.hash.toLowerCase().indexOf('faqpara') > -1) {
        pageScrollFaq();
    }
    let currentlang = locale.toLowerCase(),
        defaultFaq,
        clicked_country;
    // defaultFaq=translations.Faq;
    switch (currentlang) {
        case 'en_gr':
            defaultFaq = translations.FaqCyprus;
            clicked_country = 'Cyprus';
            break;
        // case 'fr_be':
        // defaultFaq=translations.FaqBelgium;
        // clicked_country='Belgium';
        // break;
        default:
            defaultFaq = translations.Faq;
    }

    const [faqContent, setFaqContent] = useState(defaultFaq);
    const [clickedCountry, setClickedCountry] = useState(clicked_country ? clicked_country : '');

    const show_hide = function (key) {
        clicked_country = key.trim();
        setClickedCountry(clicked_country);
        // faqContent = translations['Faq' + key];
        setFaqContent(translations['Faq' + key]);

        /*  if(clicked_country  === 'Luxembourgh'){
              faqContent=translations['FaqLuxembourg'];
          } else if(clicked_country === 'Belgium') {
              faqContent=translations['FaqBelgium'];
          } else if(clicked_country === 'Malta') {
              faqContent=translations['FaqMalta'];
          } else if(clicked_country === 'Iceland') {
              faqContent=translations['FaqIceland'];
          } else{
              faqContent=translations['Faq'+key];
          }*/
    };
    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_FaqPageTitle + ' | ' + translations.CampaignName}
                description={translations.MetaContent}
                keywords=''
            />
            <section className="popup active faq-page">
                <div id="FAQ" className="popup-content active">
                    {
                        (currentlang != 'el_gr' && currentlang != 'az_az') && <h1 >{translations.CoreKelloggs_FooterFaq}</h1>
                    }
                    {
                        currentlang == 'el_gr' && <h1 >ΣΥΧΝΕΣ ΕΡΩΤΗΣΕΙΣ (FAQ)</h1>
                    }
                    {
                        currentlang == 'en_gr' && <ol className="extra-country-nav">
                            {
                                currentlang == 'en_gr' && <li onClick={() => show_hide('Cyprus')}
                                    className={clickedCountry !== 'Cyprus' ? 'active inactive' : 'active'}
                                >{translations.LabelCyprus}</li>
                            }
                            {
                                currentlang == 'en_gr' && <li onClick={() => show_hide('Malta')}
                                    className={clickedCountry === 'Malta' ? 'active' : ''}
                                >{translations.LabelMalta}</li>
                            }
                            {/* {
                                currentlang == 'en_gr' && <li onClick="show_hide('Iceland')"
                                    className={clickedCountry === 'Iceland' && 'active'}
                                >{translations.LabelIceland}</li>
                            }
                            {
                                currentlang == 'fr_be' && <li onClick="show_hide('Belgium')"
                                    className={'active ' + clickedCountry !== 'Belgium' && 'inactive'}
                                >{translations.LabelBelgium}</li>
                            }
                            {
                                currentlang == 'en_gr' && <li onClick="show_hide('Luxembourg')"
                                    className={clickedCountry === 'Luxembourg' && 'active'}
                                >{translations.LabelLuxembourg}</li>
                            } */}

                        </ol>
                    }
                    {/* <!-- <h2 data-ng-bind-html="main.resources.CoreKelloggs_FooterFaq" ></h2> --> */}
                    <p dangerouslySetInnerHTML={{ __html: faqContent }}></p>
                </div>
            </section>
        </>
    )
}

export default FAQ;