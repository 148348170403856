import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import App from './App';
import { locale } from './Components/Utility/Global';
import { LoadJson } from './Components/Utility/JsonProvider';

document.querySelector('html').classList.add(locale.toLocaleLowerCase());

ReactDOM.render(
  <BrowserRouter>
    <LoadJson>
      <App />
    </LoadJson>
  </BrowserRouter>,
  document.getElementById('root')
);