import React, { useContext, useState } from 'react';
import { locale, LoadGRM, $, isCDC } from '../Utility/Global'
import JsonContext from '../Utility/JsonProvider';
import * as webStorageService from '../Utility/WebStorageFactory';
import Button from '../Partials/Button';
const Login = () => {
    const { translations } = useContext(JsonContext);
    const [callOnce, setCallOnce] = useState(false);

    if (!callOnce) {
        LoadGRM('login', function () {
            disableRegister = false;
            if ($('.hiding').length > 0) {
                $('.hiding').addClass('hide');
            }
        });
        setCallOnce(true);
    }

    var disableRegister,
        ua = navigator.userAgent.toLowerCase();
    document.querySelector('body').setAttribute('pagename', 'Login');

    return (
        <>
       <section className="login-page">
                <div className="twocols">

                    <div className="title-content">
                        <div>
                            <div className="hiding" role="alert" >{translations.LoginPageHeading}</div>
                            <h1><span>{translations.LoginPageHeading}</span></h1>
                            <h2 dangerouslySetInnerHTML={{ __html: translations.LoginPageDescription }}></h2>
                            <p dangerouslySetInnerHTML={{ __html: translations.LoginPageDescription1 }}></p>
                            <p className="tnclink">
                                <span><sup>▴</sup></span>
                                <a target="_blank" title="Link of T&amp;Cs apply" href={'/' + locale + '/TERMS'}>
                                    {translations.CoreKelloggs_TncApply}
                                </a>
                            </p>
                            <h2 className='sign-up'>{translations.LoginSectionHeading} </h2>
                            <picture picturefill="true" className='arrow'>
                                <source srcSet="/Content/img/home/nba2023-red-arrow.gif" media="only screen and (min-width: 768px)" />
                                <source srcSet="/Content/img/home/nba2023-red-arrow-mob.gif" media="only screen and (max-width: 767px)" />
                                <img src="/Content/img/home/nba2023-red-arrow-mob.gif" alt=""/>
                                
                            </picture>

                        </div>
                    </div>
                    <div>
                       {!isCDC && <div>
                            <p className="mndtry-desc" dangerouslySetInnerHTML={{ __html: translations.CoreKelloggs_RegisterPageMandatoryCopy }}></p>
                            <div id="reg-container" className="register-form"></div>
                            {/* <div className="divider">{translations.Or}</div> */}

                            <p className="register"><span>{translations.RegisterNowCopy}</span> <a className="register-link" href={'/' + locale + '/Register'}><span dangerouslySetInnerHTML={{ __html: translations.RegisterNowCopy1 }}></span></a></p>
                        </div>
                        }
                        {isCDC && <div>
                            <div id="reg-container" className="register-form"></div>
                        </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login;