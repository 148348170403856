import React, { useContext, useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { localeList } from './Components/Utility/ConfigData.json';
import { locale } from './Components/Utility/Global';

import Header from './Components/Partials/Header';
import Footer from './Components/Partials/Footer';

import Closed from './Components/Screens/Closed';
import Comingsoon from './Components/Screens/Comingsoon';
import Splitter from './Components/Screens/Splitter';
import Home from './Components/Screens/Home';
import Landing from './Components/Screens/Landing';
import Register from './Components/Screens/Register';
import Forgotpassword from './Components/Screens/Forgotpassword';
import Recoverpassword from './Components/Screens/Recoverpassword';
import Batchcode from './Components/Screens/Batchcode';
import Movie from './Components/Screens/Movie';
import Wordsearch from './Components/Screens/Wordsearch';
import Puzzle from './Components/Screens/Puzzle';
import Thankyou from './Components/Screens/Thankyou';
import Limit from './Components/Screens/Limit';
import Error from './Components/Screens/Error';
import FAQ from './Components/Screens/FAQ';
import Terms from './Components/Screens/Terms';
import About from './Components/Screens/About';
import Privacypolicy from './Components/Screens/Privacypolicy';
import JsonContext from './Components/Utility/JsonProvider';
import './Components/Utility/vendor/criticalcom';
import PromotionState from './Components/Utility/PromotionState';

import './Styles/nba2023.scss';
var $ = require('jquery');
window.$ = $;
window.jQuery = $;
function App() {
  const { translations, settings } = useContext(JsonContext);
  const [loaded, setLoaded] = useState(false);
  const loadApp = (translations !== "" && translations !== undefined && settings !== "" && settings !== undefined) ? true : false;

  if (loadApp && !loaded) {
    setLoaded(true);
    const { initPage } = PromotionState(settings, translations);
    initPage();
    sessionStorage.removeItem('btnObj');
    sessionStorage.removeItem('shippingBtnObj');
    sessionStorage.removeItem('receiptObj');
    if ((window.location.href.toLowerCase()).indexOf('thankyou') > -1) {
      sessionStorage.removeItem('createPageState');
    }
  }
  const faqTnc = (window.location.href.toLowerCase()).indexOf('faq') > -1 || (window.location.href.toLowerCase()).indexOf('terms') > -1 || (window.location.href.toLowerCase()).indexOf('privacynotice') > -1 || (window.location.href.toLowerCase()).indexOf('aboutnbajrleague') > -1;
  let lang = localeList.find(x => x.locale === locale).language;

       //   <!-- AA DATALAYER Implementation START -->
       let windowOriginURL = window.location.origin,
       env, src;
   if(windowOriginURL.indexOf('dev-') >= 0){
       env = 'Dev';
       src= 'https://assets.adobedtm.com/0e8da62b9d32/41f48040261d/launch-cb7f296524be-development.min.js'
   }else if(windowOriginURL.indexOf('staging-') >= 0){
       env = 'Staging';
       src= 'https://assets.adobedtm.com/0e8da62b9d32/41f48040261d/launch-cb7f296524be-development.min.js'
   }else if(windowOriginURL.indexOf('stage-') >= 0){
       env = 'stage';
       src= 'https://assets.adobedtm.com/0e8da62b9d32/41f48040261d/launch-52cbfe038952-staging.min.js'
   }else{
      
       env = 'Prod'
       src= 'https://assets.adobedtm.com/0e8da62b9d32/41f48040261d/launch-32eb2f92e708.min.js'
   }
   useEffect(()=>{
      //  const base = () => {
           var s = document.createElement("script");
           s.type = "text/javascript";
           s.src = "/Content/AAdatalayer/base.min.js";
           $('body').append(s);
           var sa = document.createElement("script");
           sa.type = "text/javascript";
           sa.src = "/Content/AAdatalayer/datalayer.min.js";
           $('body').append(sa);
      //  }
      //  base();
       window.digitalData = window.digitalData || [];
       window.digitalData.page.promotionname=  "Kelloggs NBA Cereals 2023";
       window.digitalData.page.propertyID = "Promotion: Kelloggs NBA Cereals 2023"
       window.digitalData.page.siteRegion= "KEU";
       window.digitalData.page.siteType= "Promotion site";
       window.digitalData.page.siteCountry = locale;
       window.digitalData.page.environment = env;
       window.digitalData.user.language = locale;
       window.digitalData.page.siteLanguage = lang;

       // Adobe launch script
       var script = document.createElement('script');
       script.text = "var KSTL = KSTL || {};KSTL.LaunchConfig = {environment: 'local',propertyID: 'test',siteRegion: 'kla'};"
       document.head.prepend(script);
 
       var AdobeLaunchScript = document.createElement("script");
       AdobeLaunchScript.type = "text/javascript";
       AdobeLaunchScript.src = src;
       document.head.prepend(AdobeLaunchScript);
      
   },[])
 //   <!-- AA DATALAYER Implementation END -->
 
  return (
    <>
      {loadApp && loaded &&
        <React.Fragment>
          <section className='main-wrapper'> 
          <Header />
          <main>
            <div className="container">
              <Switch>
                <Route exact path={'/' + locale + '/Closed'}>
                  <Closed />
                </Route>
                <Route exact path={'/' + locale + '/Comingsoon'}>
                  <Comingsoon />
                </Route>
                <Route exact path={'/ch'}>
                  <Splitter />
                </Route>
                <Route exact path={'/be'}>
                  <Splitter />
                </Route>
                <Route exact path={'/be/:uuid'}>
                  <Redirect to={'/' + locale} />
                </Route>
                <Route exact path={'/ch/:uuid'}>
                  <Redirect to={'/' + locale} />
                </Route>
                <Route exact path={'/' + locale + '/Landing'}>
                  <Landing />
                </Route>
                <Route exact path={'/' + locale + '/Home'}>
                  <Home />
                </Route>
                <Route exact path={'/' + locale + '/Register'}>
                  <Register />
                </Route>
                <Route exact path={'/' + locale + '/ForgotPassword'}>
                  <Forgotpassword />
                </Route>
                <Route exact path={'/' + locale + '/RecoverPassword'}>
                  <Recoverpassword />
                </Route>
                <Route exact path={'/' + locale + '/Batchcodeentry'}>
                  <Batchcode />
                </Route>
                <Route exact path={'/' + locale + '/Movie'}>
                  <Movie />
                </Route>
                <Route exact path={'/' + locale + '/Crossword/:uuid'}>
                  <Wordsearch />
                </Route>
                <Route exact path={'/' + locale + '/Puzzle/:uuid'}>
                  <Puzzle />
                </Route>
                <Route exact path={'/' + locale + '/Thankyou/:uuid'}>
                  <Thankyou />
                </Route>
                <Route exact path={'/' + locale + '/Limit'}>
                  <Limit />
                </Route>
                <Route exact path={'/' + locale + '/Error/:uuid'}>
                  <Error />
                </Route>
                <Route exact path={'/' + locale + '/FAQ'}>
                  <FAQ />
                </Route>
                <Route exact path={'/' + locale + '/Terms'}>
                  <Terms />
                </Route>
                <Route exact path={'/' + locale + '/AboutNBAJRLeague'}>
                  <About />
                </Route>
                {/* <Route exact path={'/' + locale + '/Privacynotice'}>
                  <Privacypolicy />
                </Route> */}
                <Redirect to={'/' + locale + '/Landing'} />
              </Switch>
             
            </div>
            <Route exact path={'/' + locale + '/Home'}>
                 <div className='blue-wrapper'>
                              
                                  <div className='col-1'>
                                    <figure>
                                        <img alt={translations.PrizeSectionText1} src="/Content/img/home/nba2023-prize1.png" />
                                        <figcaption>{translations.PrizeSectionText1}</figcaption>
                                    </figure>
                                  </div>
                                  <div className='col-2'>
                                  <figure>
                                  <figcaption>{translations.PrizeSectionText2}</figcaption>
                                      <img alt={translations.PrizeSectionText2} src="/Content/img/home/nba2023-prize2.png" />
                                  </figure>
                                  </div>
                    </div>
                </Route>
          </main>
          </section>
          {
            locale != 'be' && locale != 'ch' && !faqTnc && <Footer />
          }
        </React.Fragment>
      }
    </>
  );
}

export default App;