import React, { useContext, useState } from 'react';
import { MetaTags, LoadGRM, $, isCDC } from '../Utility/Global';
import * as webStorageService from '../Utility/WebStorageFactory';
import JsonContext from '../Utility/JsonProvider';

const Register = () => {
    const { translations } = useContext(JsonContext);
    const [callOnce, setCallOnce] = useState(false);
    webStorageService.removeSessionData('codes');
    webStorageService.removeSessionData('createPageState');
    document.querySelector('body').setAttribute('pagename', 'Register');
    if (!callOnce) {
        LoadGRM('register', function () {
            if ($('.hiding').length > 0) {
                $('.hiding').addClass('hide');
            }
        });
        setCallOnce(true);
        const _intervalR = setInterval(function () {
            setFocus();
        }, 300);

        function setFocus() {
            if (document.getElementById('KSTL-Registration-FirstName') != null) {
                document.getElementById('KSTL-Registration-FirstName').focus();
                clearInterval(_intervalR);
            }
        }

    }

    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_RegisterPageTitle + ' | ' + translations.CampaignName}
                description={translations.MetaContent}
                keywords=''
            />
            {!isCDC && <section className="register-page">
                <h1>{translations.CoreKelloggs_RegisterPageTitle_klg}</h1>
                <p>{translations.ValidEmailDirections_register}</p>
                <p className="mndtry-desc" dangerouslySetInnerHTML={{ __html: translations.CoreKelloggs_RegisterPageMandatoryCopy }}></p>
                <div className="hiding" role="alert">{translations.CoreKelloggs_RegisterPageHeading}</div>
                {/* <p className="instruction">{translations.LoginPageTitle}</p> */}
                <div id="reg-container" className="register-form"></div>
            </section>
            }
            {isCDC && <section className="register-page">
                <div id="reg-container" className="register-form"></div>
            </section>
            }
        </>
    )
}

export default Register;