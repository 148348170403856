import React, { useContext } from 'react';
import { MetaTags, $ } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';

const About = () => {
    const { translations } = useContext(JsonContext);
    document.querySelector('body').setAttribute('pagename', 'FAQ');
    return (
        <>
            <MetaTags
                title={translations.AboutPageTitle + ' | ' + translations.CampaignName}
                description={translations.MetaContent}
                keywords=''
            />
            <section className="about-page popup active faq-page">
                <div className='popup-content active'>
                <h1>{translations.AboutPageTitle}</h1>
                <p dangerouslySetInnerHTML={{ __html: translations.AboutPageContent }}></p>

                </div>
            </section>
        </>
    )
}

export default About;