import { useHistory } from "react-router-dom";
import { useContext } from 'react';
import * as webStorageService from './WebStorageFactory';
import { locale, pageScroll } from './Global';
import JsonContext from './JsonProvider';

const ErrorProvider = () => {
    const { translations } = useContext(JsonContext);
    const history = useHistory();
    return {
        HandleError: (error) => {
            webStorageService.setSessionData('createPageState', 'Error');
            if (error!=undefined) {
                if (error.status === 500 || error.status === 503 || error.status === 400 || error.status === 403) {
                    if (error.data.ErrorCode == undefined) {
                        webStorageService.setSessionData('ErrorCode', error.status);
                        history.push('/' + locale + '/Error/' + error.status);
                        let errMsgInvalid = (translations.CoreKelloggs_GeneralErrorMsg_klg).replace("100", error.status);
                        webStorageService.setSessionData('ErrorKey', errMsgInvalid);
                    } else {
                        webStorageService.setSessionData('ErrorKey', translations[error.data.ErrorMessage]);
                        webStorageService.setSessionData('ErrorCode', error.data.ErrorCode);
                        history.push('/' + locale + '/Error/' + error.data.ErrorCode);
                    }
                    pageScroll();
                }
            } else {
                history.push('/' + locale + '/Error/100');
            }
        }
    }
};

export default ErrorProvider;