import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { locale } from "../Utility/Global";
import dataLayers from "../Utility/DataLayers";
import JsonContext from "../Utility/JsonProvider";

const Button = ({ buttonType }) => {
  const { translations } = useContext(JsonContext);
  let title, txt, redirect;
  switch (buttonType) {
    case "register":
      title = translations.CoreKelloggs_RegisterNowCtaTitle;
      txt = translations.CoreKelloggs_RegisterNowButton;
      redirect = '/' + locale + '/Register'
      break;
    default:
      title = translations.CoreKelloggs_ReturnToHomepageCtaTitle;
      txt = translations.CoreKelloggs_BackToHomePage;
      redirect = "/" + locale + "/Home";
  }

  return (
    <>
      <Link
        to={redirect}
        onClick={buttonType ? undefined : () => dataLayers().backToHP()}
        className="button"
        title={title}
      >
        <span className="btn-copy ">{txt}</span>
      </Link>
    </>
  );
};

export default Button;
