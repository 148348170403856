import React, { useState, useContext ,useEffect} from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { locale, isShowReceiptField, MetaTags, $, pageScroll } from '../Utility/Global';
import * as API from '../Utility/ApiCalls';
import * as webStorageService from '../Utility/WebStorageFactory';
import JsonContext from '../Utility/JsonProvider';
import ErrorProvider from '../Utility/ErrorProvider';
import dataLayers from '../Utility/DataLayers';
const Batchcode = () => {
    const history = useHistory();
    const { HandleError } = ErrorProvider();
    const _intervalG = setInterval(function () {
        logout();
    }, 300);
    function logout() {
        if (window.gigya != undefined) {
            window.gigya.accounts.logout();
            clearInterval(_intervalG);
        }
    }
    const handleRoute = (path, obj) => {
        if (path.toLowerCase().indexOf('thankyou') > -1) {
            webStorageService.setSessionData('createPageState', 'Thankyou');
        } else if (path.toLowerCase().indexOf('puzzle') > -1) {
            webStorageService.setSessionData('createPageState', 'Puzzle');
        }
        else if (path.toLowerCase().indexOf('crossword') > -1) {
            webStorageService.setSessionData('createPageState', 'Crossword');
        }
        else if (path.toLowerCase().indexOf('limit') > -1) {
            webStorageService.setSessionData('createPageState', 'Limit');
        }
        history.push({ pathname: path, state: obj });
        pageScroll();
    }
    const [codesArrObj, setCodesArrObj] = useState([{
        'objId': '',
        'id': '',
        'errors': '',
        'labels': '',
        'value': '',
        'uploadId': '',
        'receiptNum': '',
        'isWritten': false,
        'duplicateKey': -1
    }]);
    const [fileNameObj, setFileNameObj] = useState([{
        receipt1: {
            name: '',
            invalid: false,
            large: false,
            empty: '',
            statusFile: '',
            statusMsg: '',
            showName: false,
            file: null
        }
    }])
    const { translations, settings } = useContext(JsonContext);
    var _interval,
        getBtnObj;

    if (webStorageService.getSessionData('btnObj') == undefined) {
        getBtnObj = [{
            'sended': false,
            'anyError': false,
            'isEmpty': true,
            'validResponse': false
        }];
    } else {
        getBtnObj = JSON.parse(webStorageService.getSessionData("btnObj"));
    }
    const [btnObj, setbtnObj] = useState(getBtnObj)
    const [modalShown, setModalShown] = useState(false);
    let pageState = webStorageService.getSessionData('createPageState');

    //pageState = 'Batchcodeentry';
    if (pageState == '' || pageState == undefined || pageState == null) {
        window.location = '/' + locale + '/Home';
    } else if (pageState != 'Batchcodeentry') {
        return <Redirect to={'/' + locale + '/' + pageState} />
    }
    let smallViewPort;
    var toggleModal = function (event) {
        event.preventDefault();
        setModalShown(!modalShown)
        if (!modalShown) {
            $('html,body').removeClass('activeOverlay');
        }
        setTimeout(function () {
            positionModal();
        }, 5);
    };
    var positionModal = function () {
        if (modalShown) {
            if ($('.ng-modal-dialog').height() > ($(window).height() - 50)) {
                smallViewPort = false;
            }
            else {
                smallViewPort = true;
            }
        }
    }
    if (pageState == 'Batchcodeentry') {
        var upcErrorLabels = {
            'length': translations.CoreKelloggs_ErrorIncompleteCode,
            'pattern': translations.CoreKelloggs_ErrorInvalidCode,
            'redeemed': translations.CoreKelloggs_ErrorInvalidCode,
            'empty': translations.CoreKelloggs_ErrorCodeRequired,
            'duplicate': translations.CoreKelloggs_ErrorDuplicateCode,
            'invalid': translations.CoreKelloggs_ErrorInvalidCode,
            'expired': translations.CoreKelloggs_ErrorExpiredCode
        },
            hidePromoCodeField,
            upcCount = settings.CodesCount;

        if (locale == 'lv_LV' || locale == 'bg_bg') {
            hidePromoCodeField = true;
        }




        var imageValid = false,
            numValid = false,
            PromotionIdNotFound = false,
            codes = [],
            updateBtnOj = getBtnObj,
            receiptAdded,
            // $anchorScroll,
            // modalShown = false,
            coordinatesToReturn = {},
            lockCrossword = false,
            showlanguageSelector = true,
            fileName = [];


        if (webStorageService.getSessionData('codes') == undefined) {
            for (var i = 1; i <= upcCount; i++) {
                codes.push({
                    'objId': i,
                    'id': 'Code' + i,
                    'errors': '',
                    'labels': 'BatchcodeSection1Label' + i,
                    'value': '',
                    'uploadId': 'uploadImg' + i,
                    'receiptNum': 'receipt' + i,
                    'isWritten': false,
                    'duplicateKey': -1
                });
            }
        } else {
            codes = JSON.parse(webStorageService.getSessionData("codes"));
        }

        var uploadReceipt = false;
        if (locale.toLowerCase() == 'it_it') {
           
            document.querySelector('body').className = 'activeOverlay';
            uploadReceipt = true;
            if (webStorageService.getSessionData('receiptObj') == undefined) {
                fileName = [{
                    receipt1: {
                        name: '',
                        invalid: false,
                        large: false,
                        empty: '',
                        statusFile: '',
                        statusMsg: '',
                        showName: false,
                        file: null
                    }
                }];
            } else {
                fileName = JSON.parse(webStorageService.getSessionData("receiptObj"));
            }
        } else {
            uploadReceipt = false;
        }

        var checkForDuplicates = function (e, index) {
            var value = e.target.value.toLowerCase();
            for (var i = 0; i < codes.length; i++) {
                if (value == codes[i].value.toLowerCase() && i != index && (!codes[i].errors || codes[i].errors == upcErrorLabels.duplicate)) {
                    codes[i].errors = upcErrorLabels.duplicate;
                    codes[index].errors = upcErrorLabels.duplicate;
                    //codes[i].duplicateKey = index;
                }
            }
            // setCodesArr(codes);
        }
        var verifyDuplicates = function (index) {
            var duplicateCount;
            for (var i = 0; i < codes.length; i++) {
                duplicateCount = 0;
                if (i != index && codes[i].errors == upcErrorLabels.duplicate) {
                    for (var j = 0; j < codes.length; j++) {
                        if (codes[i].value.toLowerCase() == codes[j].value.toLowerCase() && i != j) {
                            duplicateCount++;
                        }
                    }
                    if (!duplicateCount && codes[i].errors == upcErrorLabels.duplicate) {
                        codes[i].errors = '';
                    }
                }
            }

        }
        var updateErrorKey = function () {
            var flag = false;
            for (var i = 0; i < codes.length; i++) {
                if (codes[i].errors) {
                    flag = true;
                }
            }
            updateBtnOj[0].anyError = (flag ? true : false);
        }
        var onBlur = function (index, e) {
            // if(!item.$viewValue){
            //     _setInvalidFieldState(item.$name, 'required');
            // }
            if (e.target.value.length < e.target.maxLength && e.target.value.length) {
                codes[index].errors = upcErrorLabels.length;
            } else if (!e.target.value.length) {
                codes[index].errors = upcErrorLabels.empty;
                updateBtnOj[0].isEmpty = true;
            } else {
                updateBtnOj[0].isEmpty = false;
            }
            updateErrorKey();
            codes[index].isWritten = true;
            webStorageService.setSessionData("codes", codes);
            setCodesArrObj(codes);
            webStorageService.setSessionData('btnObj', updateBtnOj);
            setbtnObj(updateBtnOj);
            // alignVertically();
        };
        var userInput = function (e, index) {
            codes[index].value = e.target.value;
            codes[index].isWritten = true;
            if (e.target.value == 0 && (codes[index].isWritten || codes[index].errors)) {
                codes[index].errors = upcErrorLabels.empty;
            }
            else if (codes[index].isWritten || codes[index].errors) {
                let transformedInput = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                e.target.value = transformedInput
                if (e.target.value.length < e.target.maxLength) {
                    codes[index].errors = upcErrorLabels.length;
                    updateErrorKey();
                }
                verifyDuplicates(index);
                updateBtnOj[0].isEmpty = false;
            }
            if (e.target.value.length == e.target.maxLength) {
                e.preventDefault();
            } else if (e.target.value.length > e.target.maxLength) {
                // Maximum exceeded
                e.target.value = e.target.value.substring(0, e.target.maxLength);
            }
            if (e.target.value.length == e.target.maxLength) {
                codes[index].errors = '';
                checkForDuplicates(e, index);
                updateErrorKey();
            }
            webStorageService.setSessionData("codes", codes);
            setCodesArrObj(codes);
            webStorageService.setSessionData('btnObj', updateBtnOj);
            setbtnObj(updateBtnOj);
            // alignVertically();
        }
        var _setStatuses = function (response) {
            var valid = true,
                statusesArr = response.result,
                statusCheck = true,
                imageResponse = uploadReceipt ? response.imageResult : '',
                receiptNum, scrollElement, filename;
            codes = webStorageService.getSessionData("codes") ? JSON.parse(webStorageService.getSessionData("codes")) : '';
            filename = webStorageService.getSessionData("receiptObj") ? JSON.parse(webStorageService.getSessionData("receiptObj")) : '';
            if (uploadReceipt && !!imageResponse) {
                for (i = 0; i < imageResponse.length; i++) {
                    if (imageResponse[i].Status.toLowerCase() == 'failed') {
                        valid = false;

                        $('.upload-img').eq(i).val(null);
                        receiptNum = 'receipt' + (i + 1);
                        filename[0][receiptNum].statusFile = true;
                        filename[0][receiptNum].showName = false;
                        receiptAdded = false;
                        filename[0][receiptNum].statusMsg = imageResponse[i].Message;
                        filename[0][receiptNum].file = null;
                        filename[0][receiptNum].name = '';
                        webStorageService.setSessionData('receiptObj', filename);
                        setFileNameObj(filename);
                        //someCtrlFn(receiptNum, null, false);
                        if (statusCheck) {
                            scrollElement = 'uploadReceipt' + (i + 1);
                            statusCheck = false;
                        }
                    }
                }
            }
            for (i = 0; i < statusesArr.length; i++) {
                if (statusesArr[i].status.toLowerCase() != 'valid') {
                    updateBtnOj[0].anyError = true;
                    updateBtnOj[0].validResponse = false;
                    webStorageService.setSessionData('btnObj', updateBtnOj);
                    setbtnObj(updateBtnOj);
                    codes[i].errors = upcErrorLabels[statusesArr[i].status.toLowerCase()];
                    valid = false;
                    if (statusCheck) {
                        scrollElement = 'Code' + (i + 1);
                        statusCheck = false;
                        // $anchorScroll.yOffset = 100;
                    }
                }
            }
            if (!statusCheck) {
                // $anchorScroll(scrollElement);
                // $('#' + scrollElement).focus();
            }
            webStorageService.setSessionData("codes", codes);
            setCodesArrObj(codes);

            //$location.hash(scrollElement);
            // call $anchorScroll()
            // alignVertically();
            return valid;
        }
        var sendForm = function (e) {
            e.preventDefault();
            receiptAdded = (uploadReceipt) ? checkEntries() : true;
            if (!receiptAdded) {
                setTimeout(function () {
                    if ($('.blank-msg').eq(0).length > 0) {
                        var elem = $('.blank-msg').eq(0).closest('.upload-receipt').attr('id');
                        // $anchorScroll(elem);
                    }
                }, 300);
            } else {
                updateBtnOj[0].sended = true;
                updateBtnOj[0].validResponse = true;

                webStorageService.setSessionData('btnObj', updateBtnOj);
                setbtnObj(updateBtnOj);

                var form = new FormData(),
                    data, codesArr = [],
                    codesInput = webStorageService.getSessionData("codes") ? JSON.parse(webStorageService.getSessionData("codes")) : '',
                    filenameVal = webStorageService.getSessionData("receiptObj") ? JSON.parse(webStorageService.getSessionData("receiptObj")) : '';

                for (var i = 0; i < codesInput.length; i++) {
                    codesArr[i] = $.trim(codesInput[i].value).toUpperCase();
                }
                if (locale.toLowerCase() == 'it_it') {
                    for (var i = 0; i < 1; i++) {

                        // form.append('receipt[]', filenameVal[0]['receipt' + (i + 1)].file);
                        form.append('PartNumber', filenameVal[0]['receipt' + (i + 1)].file);

                    }
                    for (var i = 0; i < codesArr.length; i++) {

                        form.append('Codes[]', codesArr[i]);
                    }
                    data = form;
                } else {
                    data = codesArr;
                }
                API.checkUpcCodes(data, webStorageService.getSessionData('servToken')).then(function (response) {
                    updateBtnOj[0].sended = false;
                    webStorageService.setSessionData('btnObj', updateBtnOj);
                    setbtnObj(updateBtnOj);
                    if (!!response.result && response.result.length > 0) {
                        if (_setStatuses(response) && response.token) {
                            webStorageService.setSessionData('servToken', response.token);
                            if (locale.toLowerCase() != 'lv_lv' && locale.toLowerCase() != 'bg_bg') {
                                dataLayers().packCodeData();
                            }
                            if (locale.toLowerCase() === 'ru_ru') {
                                var shipObj = webStorageService.getSessionData('shippingInfoObj');
                                var shippingInfoObj = {
                                    'redemption-token': response.token,
                                    'shippingDetails': {
                                        name: shipObj.shippingDetails.name,
                                        lastName: shipObj.shippingDetails.lastName,//formObj.LastName,
                                        address1: shipObj.shippingDetails.address1,//formObj.Address1,
                                        address2: shipObj.shippingDetails.address2,//formObj.Address2,
                                        city: shipObj.shippingDetails.city,//formObj.City,
                                        postCode: shipObj.shippingDetails.postCode,//formObj.PostalCode,
                                        state: shipObj.shippingDetails.state,
                                        Email: shipObj.shippingDetails.Email,
                                        terms: shipObj.shippingDetails.terms,
                                        subscription: shipObj.shippingDetails.subscription,
                                        phone: shipObj.shippingDetails.phone,
                                        country: shipObj.shippingDetails.country// resourceService.getCountryCode().toLowerCase() // add russia
                                    }
                                };
                            }
                            else {
                                var grm = webStorageService.getSessionData('grmUser');
                                var shippingInfoObj = {
                                    'redemption-token': webStorageService.getSessionData('servToken'),
                                    'shippingDetails': grm
                                };
                            }

                            /*sended = true;*/

                            API.orderAward(shippingInfoObj).then(function (response) {
                                // sended = false;
                                /*disableRegister = false;*/
                                if (response.token) {
                                    // var token = response.token;
                                    webStorageService.setSessionData('servToken', response.token);
                                    webStorageService.setLocalData('servToken', response.token);
                                } else {
                                    webStorageService.removeSessionData('servToken');
                                }
                                if (response.redirect) {
                                    updateBtnOj[0].validResponse = false;
                                    webStorageService.setSessionData('btnObj', updateBtnOj);
                                    setbtnObj(updateBtnOj);
                                    var winnerObj = {};
                                    if (response.redirect.toLowerCase().indexOf('thankyou') > -1) {
                                        if (response.Winner !== undefined && response.Winner !== null) {
                                            winnerObj = {
                                                winner: response.Winner,
                                                isExtendedPhase: response.ExtendedPhase,
                                                refCode: response.RefCode
                                            }
                                            if(!response.ExtendedPhase){
                                                if(locale.toLowerCase() == 'it_it'){
                                                    if(response.Winner > 0){
                                                    dataLayers().orderDataIT(response.Winner);
                                                }
                                                }else{
                                                dataLayers().orderData();
                                                }
                                            }
                                        }
                                    }
                                    if (response.redirect.indexOf('limit') > -1) {
                                        webStorageService.setSessionData('limitType', response.message);
                                    }
                                    handleRoute(response.redirect, winnerObj);
                                } else {
                                    // $location.path(locale + '/Error');
                                }
                            }).catch(function (error) {
                                HandleError(error.response);
                            });
                        }

                    }
                }).catch(function (error) {
                    HandleError(error.response);
                });
            }
        };
        // var onClick = function (clicks) {
        //     clicks = clicks;
        //     this.options.registerClicks(clicks);
        // }
        var checkEntries = function () {
            receiptAdded = true;
            let filenameVal = webStorageService.getSessionData("receiptObj") ? JSON.parse(webStorageService.getSessionData("receiptObj")) : [{
                receipt1: {
                    name: '',
                    invalid: false,
                    large: false,
                    empty: '',
                    statusFile: '',
                    statusMsg: '',
                    showName: false,
                    file: null
                }
            }];
            for (var key in filenameVal[0]) {
                if (filenameVal[0][key].name.length < 1) {
                    filenameVal[0][key].empty = true;
                    filenameVal[0][key].invalid = false;
                    filenameVal[0][key].large = false;
                    filenameVal[0][key].statusFile = false;
                } else {
                    filenameVal[0][key].empty = false;
                }
                if (filenameVal[0][key].file == null) {
                    receiptAdded = false;
                }
            }
            webStorageService.setSessionData('receiptObj', filenameVal);
            setFileNameObj(filenameVal);
            return receiptAdded;
        }
        // var someCtrlFn = function (arg1, arg2, field) {
        //     var validEntry = true;
        //     filename[arg1].file = arg2;
        //     imageValid = false;
        //     if (field) {
        //         filename[arg1].statusFile = false;
        //     }
        //     receiptAdded = true;
        // };

        var onTextChange = function (e, index) {
            var $el = $(e.target);
            var maxLength = $el.attr('maxLength');
            maxLength = maxLength ? maxLength : 10;
        };
        var onUploadClick = function ($event) {
            $event.preventDefault();
            var triggerEl = $('#' + $($event.target).data('clickfor'));
            triggerEl.trigger('click');
        };
        var validType = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png']
            , validSize = 5;
        var onChangeReceipt = function (event) {
            const reader = new FileReader();
            let file = event.target.files[0],
                elementName = event.target.name;
            fileName[0][elementName].name = '';
            fileName[0][elementName].invalid = false;
            fileName[0][elementName].size = false;
            fileName[0][elementName].showName = false;
            fileName[0][elementName].statusFile = false;
            fileName[0][elementName].large = false;
            fileName[0][elementName].empty = false;

            if (file == undefined) {
                file = null;
            }
            else if (validType.indexOf(file.type) < 0) {
                event.target.value = null;
                file = null;
                fileName[0][elementName].invalid = true;
                fileName[0][elementName].large = false;
            }
            else if ((file.size) / (1024 * 1024) > validSize) {
                event.target.value = null;
                file = null;
                fileName[0][elementName].invalid = false;
                fileName[0][elementName].large = true;
            }
            else {
                fileName[0][elementName].name = file.name;
                fileName[0][elementName].showName = true;
                dataLayers().imageUpload()
            }
            reader.addEventListener("load", function () {
                // convert image file to base64 string
                fileName[0][elementName].file = reader.result;
                webStorageService.setSessionData('receiptObj', fileName);
                setFileNameObj(fileName);
            }, false);
            if(file){
                reader.readAsDataURL(file);
            }else{
                setFileNameObj(fileName);
            }
        }
        var isOutOfHours = webStorageService.getSessionData('pageState') ?
            webStorageService.getSessionData('pageState') === 'Outofhours' : false;
        //isOutOfHours=true;
        // if (isOutOfHours) dataLayers().OutOfHours();
        var goToPringlesSite = function () {
            // dataLayers().goToPringles('Out of Hours Page');
        };

        document.querySelector('body').setAttribute('pagename', 'Batchcodeentry');

    }
    return (
        <>
            <MetaTags
                title={translations.UpccodePageTitle + ' | ' + translations.CampaignName}
                description={translations.MetaContent}
                keywords=''
            />
             
            <section className="batchcode-page">
                <div className="twocols" >
                    <div>
                        <div className="bg-home js-col-with-form" >
                        <h1>{translations.BatchcodeSectionTitle}</h1>
                            <p dangerouslySetInnerHTML={{ __html: translations.BatchcodeSectionDescription + " " + translations.BatchcodeSectionDescription1 }}></p>
                          <div>
                                <form id="upcCodeForm" name="upcForm" method="post" className="form-box" noValidate="novalidate">
                                    <div className="form-control" >

                                        {
                                            codes.map(function (code, index) {
                                                return (
                                                    <div key={index}>
                                                        <label htmlFor={code.id}>{translations.BatchcodeSectionLabel}</label>

                                                        <div role="alert" aria-atomic="true" className={codesArrObj[index].errors ? 'error error-message':"error-message"}>
                                                            <p>
                                                                <span>{codesArrObj[index].errors}</span>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <input type="text" id={code.id} name={code.id} required={true} autoComplete="off" minLength='10' maxLength='10'
                                                                pattern="^([A-Za-z0-9]{10})$" placeholder="XXXXXXXXXX"
                                                                onChange={(e) => userInput(e, index)}
                                                                onBlur={(e) => onBlur(index, e)}
                                                                className={codesArrObj[index].errors && 'ng-error'}
                                                                onKeyUp={(e) => onTextChange(e, index)}
                                                                title={translations.CoreKelloggs_EnterCodeInputFieldTitle} />

                                                        </div>
                                                        {
                                                            isShowReceiptField && <div className="upload-receipt" id={'uploadReceipt' + (index + 1)}>
                                                                <p>
                                                                    <span>{translations.CoreKelloggs_UploadImageHeader}</span>
                                                                </p>
                                                                {fileNameObj[0][code.receiptNum].invalid && <span className="error">{translations.CoreKelloggs_UploadImageTypeError}</span>}
                                                                {fileNameObj[0][code.receiptNum].large && <span className="error">{translations.CoreKelloggs_UploadImageSizeError}</span>}
                                                                {fileNameObj[0][code.receiptNum].empty && <span className="error blank-msg">{translations.CoreKelloggs_UploadImageMandatoryError}</span>}
                                                                {fileNameObj[0][code.receiptNum].statusFile && <span className="error" data-attr={fileNameObj[0][code.receiptNum]}>{fileNameObj[0][code.receiptNum].statusMsg}</span>}
                                                                {fileNameObj[0][code.receiptNum].showName && <span className="file-name" ><span className="icon"></span>{fileNameObj[0][code.receiptNum].name}</span>
                                                                }
                                                                <div className="receipt-wrapper">
                                                                    <a href="#" data-clickfor={code.receiptNum} className="button small not-button" onClick={(e) => onUploadClick(e)} title={translations.CoreKelloggs_ReceiptUploadCtaTitle}>{translations.CoreKelloggs_UploadImageCta}
                                                                    </a>
                                                                    <input required={true} name="receipt1" type="file" accept="image/*" id="receipt1" className="upload-img ng-isolate-scope" data-asd="receipt1" data-code="code" data-file-name="filename" data-options="options" onChange={(e) => onChangeReceipt(e)} data-check-entries="checkEntries" />

                                                                    <div className="info-wrapper"><a href="#" className="btn-info" onClick={(event) => toggleModal(event)} title={translations.CoreKelloggs_ReceiptUploadIconTitle}><span>i</span></a></div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    <button id="homeBtn" type="submit" onClick={(e) => sendForm(e)}
                                        disabled={btnObj[0].sended || btnObj[0].anyError || btnObj[0].isEmpty}
                                        className={(btnObj[0].validResponse) ? 'inactive processing' : (btnObj[0].sended || btnObj[0].anyError || btnObj[0].isEmpty) ? 'inactive' : ''}
                                        title={translations.EnterNowCtaTitle}
                                        aria-disabled={btnObj[0].sended || btnObj[0].anyError || btnObj[0].isEmpty}
                                    >
                                        <span>{translations.CoreKelloggs_BatchcodeSectionCta}</span>
                                        <i className="loader-wrapper" ><span className="loader"></span></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="bg-home-img-cont js-col-without-form">
                            <h2>{translations.CoreKelloggs_CodehelpSection1Title}</h2>
                            <picture picturefill="true">
                                {/* <!--[if IE 9]><video style="display: none;"><![endif]--> */}
                                <source srcSet={'/Content/img/can-visuals/nba2023-can.png'}
                                    media="only screen and (min-width: 768px)" />
                                <source srcSet={'/Content/img/can-visuals/nba2023-can.png'}
                                    media="only screen and (max-width: 767px)" />
                                {/* <!--[if IE 9]></video><![endif]--> */}
                                <img src={'/Content/img/can-visuals/nba2023-can.png'}
                                    alt={translations.CanImageAltText} />
                            </picture>
                            <p className="below-can">{translations.CodehelpSection1Instruction}</p>
                        </div>
                    </div>
                </div>
                {modalShown && <div className='ng-modal-wrapper' >
                    {modalShown && <div className='ng-modal'>
                        <div className='ng-modal-overlay' onClick={(event) => toggleModal(event)}></div>
                        <div className='ng-modal-dialog'
                            data-smallv={String(smallViewPort)}>
                            <div className='ng-modal-close' onClick={(event) => toggleModal(event)}></div>
                            <div className='ng-modal-dialog-content'>
                                <div className="heading-container">
                                <h2>{translations.CoreKelloggs_Uploadimageinstructionsheading}</h2>
                                   
                                </div>
                                <div className="instructions-list">
                                    <p dangerouslySetInnerHTML={{ __html: translations.CoreKelloggs_UploadImageInstructions }}></p>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>}
            </section>
        </>
    )
}

export default Batchcode;


