import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { relativeURL, locale, $ } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
import dataLayers from '../Utility/DataLayers';
import PromotionState from '../Utility/PromotionState';
import * as webStorageService from '../Utility/WebStorageFactory';

const Footer = () => {
    const { translations, settings } = useContext(JsonContext);
    const { getStates } = PromotionState(settings, translations);
    var holdingPage = (getStates().getPromotionState().toLowerCase() == 'holdingpage') ? true : false;
    var closedPage = (getStates().getPromotionState().toLowerCase() == 'closed') ? true : false;

    let hostName = window.location.hostname,
        shareUrl = (hostName == 'nba.kelloggs.com') ? hostName + '/Content/s_/' + locale + '/' : hostName + '/Content/s_dev/' + locale + '/';

    const shareFacebook = (e) => {
        e.preventDefault();
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + shareUrl, 'targetWindow', 'toolbar=no, location=no, status=no,  menubar=no, scrollbars=yes, resizable=yes,width=550,  height=450');
        dataLayers().socialData('Facebook');
        return false;
    }

    const shareTwitter = (e) => {
        e.preventDefault();
        let twitterData = e.currentTarget.dataset.twittertext;
        window.open('https://twitter.com/intent/tweet?url=' + shareUrl + '&text=' + twitterData, 'targetWindow', 'toolbar=no, location=no, status=no,  menubar=no, scrollbars=yes, resizable=yes,width=550,  height=450');
        dataLayers().socialData('Twitter');
        return false;
    }

    const cookiePolicy = () => {
        setTimeout(function () {
            if (document.getElementById('PlaceidTrust')) {
                if ($('#cookieContainer').length > 0 && !$('.truste-script').length) {
                    let country = locale.split('_')[1].toLowerCase(),
                        language = locale.split('_')[0],
                        src = translations.CoreKelloggs_CookieKl + '&language=' + language,
                        idTrust = src.split('&')[1].split('=')[1];
                    document.getElementById('PlaceidTrust').setAttribute('id', idTrust);
                    $('.trusty').append('<script type="text/javascript" className="truste-script" src="' + src + '"></script>');
                }
            }
        }, 1000);
    }
    cookiePolicy();

    return (
        <>
            <footer>
                {!holdingPage && !closedPage ? <div>
                    <div className="social-sharing">
                        <div>
                            <button onClick={shareFacebook} title={translations.CoreKelloggs_FacebookSharingLinkTitle} className="fb-share not-button" aria-label={translations.CoreKelloggs_FacebookSharingLinkTitle} data-fbtext={translations.FacebookSharingDescription}><img src={relativeURL + '/Content/img/icons/nba2023-fb-icon.png'} alt={translations.CoreKelloggs_FacebookImageAltText} /></button>
                        </div>
                        <div>
                            <button onClick={shareTwitter} className="twitter-share not-button" aria-label={translations.CoreKelloggs_TwitterSharingLinkTitle} title={translations.CoreKelloggs_TwitterSharingLinkTitle} data-twittertext={translations.TwitterSharingDescription}><img src={relativeURL + '/Content/img/icons/nba2023-twitter-icon.png'} alt={translations.CoreKelloggs_TwitterImageAltText} /></button>
                        </div>
                    </div>
                    <ul className="footer-menu">
                        {/* <!-- don't display in ie<=9 !--> */}
                        <li className="ie-no">
                            <Link to={'/' + locale + '/FAQ'} target="_blank" className="footerlink-faq" title={translations.CoreKelloggs_FaqsLinkTitle}>{translations.CoreKelloggs_FooterFaq}</Link>
                        </li>
                        <li className="ie-no">
                            <Link to={'/' + locale + '/TERMS'} target="_blank" className="footerlink-terms" title={translations.CoreKelloggs_TermsNConditionsLinkTitle}>{translations.CoreKelloggs_FooterTerms}</Link>
                        </li>
                        {/* {
                            isFR &&
                            <li>
                                <Link to={translations.CoreKelloggs_FooterMentionsSanitairesLink} target="_blank" className="footerlink-terms" title={translations.CoreKelloggs_FooterMentionsSanitairesLinkTitle} >{translations.CoreKelloggs_FooterMentionsSanitaires}</Link>
                            </li>
                        } */}
                      <li>
                            <a href={translations.CoreKelloggs_FooterPrivacyPolicyLink} target="_blank" title={translations.CoreKelloggs_PrivacyNoticeLinkTitle} dangerouslySetInnerHTML={{ __html: translations.CoreKelloggs_FooterPrivacyPolicy }}></a>
                        </li> 
                        
                        <li>
                            <a href={translations.CoreKelloggs_FooterKlgLink} title={translations.CoreKelloggs_KelloggsLinkTitle} target="_blank">{translations.CoreKelloggs_FooterKlg}</a>
                        </li>
                        <li title="Link to view cookie settings">
                            {/* <a nohref="nohref" className="ot-sdk-show-settings">Cookie Preferences</a> */}
                            <button className="ot-sdk-show-settings link__button">Cookie Preferences</button>
                        </li>
                    </ul>
                    <div className="copyrights" dangerouslySetInnerHTML={{ __html: translations.FootListCompany }}></div>
                    {translations.FooterShortTerms && <div className="short-terms" dangerouslySetInnerHTML={{ __html: translations.FooterShortTerms }}></div>}
                </div> : <div>
                    <ul className="footer-menu">
                        {/* <!-- don't display in ie<=9 !--> */}
                        <li>
                            <a href={translations.CoreKelloggs_FooterPrivacyPolicyLink} target="_blank" title={translations.CoreKelloggs_PrivacyNoticeLinkTitle} dangerouslySetInnerHTML={{ __html: translations.CoreKelloggs_FooterPrivacyPolicy }}></a>
                        </li> 
                        <li>
                            <a href={translations.CoreKelloggs_FooterKlgLink} title={translations.CoreKelloggs_KelloggsLinkTitle} target="_blank">{translations.CoreKelloggs_FooterKlg}</a>
                        </li>
                        <li title="Link to view cookie settings">
                            {/* <a nohref="nohref" className="ot-sdk-show-settings">Cookie Preferences</a> */}
                            <button className="ot-sdk-show-settings link__button">Cookie Preferences</button>
                        </li>
                       
                    </ul>
                    <div className="copyrights" dangerouslySetInnerHTML={{ __html: translations.FootListCompany }}></div>
                </div>}
            </footer>
            {/* <div id="cookieContainer" className="cookies"></div>
            <div id="PlaceidTrust" className="trusty"></div> */}

        </>
    )
}

export default Footer;