import React, { useState, useContext, useEffect } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import * as webStorageService from '../Utility/WebStorageFactory';
import { locale, MetaTags, $, pageScroll } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
import ErrorProvider from '../Utility/ErrorProvider';
import dataLayers from '../Utility/DataLayers';
import * as API from '../Utility/ApiCalls';

const Wordsearch = () => {
    const { translations } = useContext(JsonContext);
    const { HandleError } = ErrorProvider();
    const history = useHistory();
    const [crossletters, setCrossletters] = useState([]);
    const [findwords, setFindwords] = useState([]);
  
    document.querySelector('body').setAttribute('pagename', 'Wordsearch');

    useEffect(() => {
        API.getCrossword(webStorageService.getSessionData('servToken'))
            .then(function (data) {
                if (data.crossword) {
                    CrossWordService.setLetters(data.crossword);
                    CrossWordService.setWords(data.words);
                    var wordsFromArr = JSON.parse(data.words),
                        wordsFrom = '';
                    for (var i = 0; i < wordsFromArr.length; i++) {
                        if (locale.toLowerCase() == "ka_ge") {
                            wordsFrom +=
                                '<p class="' +
                                wordsFromArr[i].toLowerCase() +
                                '">' +
                                wordsFromArr[i].toLowerCase() +
                                '</p>';
                        }
                        else {
                            wordsFrom +=
                                '<p class="' +
                                wordsFromArr[i].toLowerCase() +
                                '">' +
                                wordsFromArr[i].toUpperCase() +
                                '</p>';
                        }
                    }
                    wordsFrom = wordsFrom;
                    setFindwords(wordsFrom);
                    CrossWordService.start();
                    isStarted = true;

                    //Directive
                    var sett = {
                        highlightColor: '#cf122e',
                        crossHeight: 0.8,
                        contener: $('.cross-word'),
                        tabScope: $('.tabContainer'),
                        button: $('.text .button')
                    }

                    //Array of words to find
                    words = CrossWordService.getWords();

                    //Array of letters
                    letters = CrossWordService.getLetters();
                    setCrossletters(letters);
                    //Current crossed line 
                    var marker = {
                        pos: {
                            x: 0,
                            y: 0
                        },
                        isActive: false
                    };

                    //isDisable
                    var isDisable = false;

                    //Canvas alement
                    var canvas = sett.contener.find('canvas')[0];
                    var context = canvas.getContext('2d');

                    //##############################
                    //PRIVATE METHODS
                    //##############################
                    var init = function (settings) {
                        $.extend(sett, settings);
                        //bindUiEvents();
                        setTimeout(function () {
                            rescale();
                        }, 2000);
                        //resize
                        $(window).on('resize', function () {
                            setTimeout(function () {
                                rescale();
                            }, 0);
                        });
                    }

                    //Returns the current position of coursor or finger
                    var pointerEventToXY = function (e) {
                        var out = { x: 0, y: 0, currentX: 0, currentY: 0 };
                        if (e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel') {
                            var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
                            out.x = touch.pageX;
                            out.y = touch.pageY;
                        } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover' || e.type == 'mouseout' || e.type == 'mouseenter' || e.type == 'mouseleave') {
                            out.x = e.pageX;
                            out.y = e.pageY;
                        }
                        out.currentX = out.x - sett.tabScope.offset().left;
                        out.currentY = out.y - sett.tabScope.offset().top;

                        out.x = Math.floor((out.x - sett.tabScope.offset().left) * (100 / sett.tabScope.width()) / 10);
                        out.y = Math.floor((out.y - sett.tabScope.offset().top) * (100 / sett.tabScope.height()) / 10);


                        return out;
                    };

                    //draws sinle line
                    var drawLine = function (start, end) {
                        var sX = ((start.x) * canvas.width / 10) + canvas.width / 10 / 2;
                        var sY = ((start.y) * canvas.height / 10) + canvas.height / 10 / 2;
                        context.beginPath();
                        context.moveTo(sX, sY);
                        if (end.currentX) {
                            context.lineTo(end.currentX, end.currentY);
                        } else {
                            context.lineTo(
                                ((end.x) * canvas.width / 10) + canvas.width / 10 / 2,
                                ((end.y) * canvas.height / 10) + canvas.height / 10 / 2
                            );
                        }
                        context.lineWidth = (canvas.width / 10) * sett.crossHeight;
                        context.lineCap = "round";
                        context.strokeStyle = sett.highlightColor;
                        context.stroke();
                    };

                    //draws all schrached lines
                    var drawScrachedLines = function () {
                        for (var i = 0; i < words.length; i++) {
                            if (words[i].isScratched) {
                                drawLine(words[i].start, words[i].end);
                            }
                        }
                    }

                    //redraw
                    var redraw = function () {
                        //update letters and words
                        letters = CrossWordService.getLetters();
                        words = CrossWordService.getWords();

                        //redraw UI
                        marker.isActive = false;
                        context.clearRect(0, 0, canvas.width, canvas.height);
                        drawScrachedLines();
                    }

                    //rescale
                    var rescale = function () {
                        sett.tabScope.css({
                            'width': '',
                            'height': ''
                        });
                        var contenerWidth = sett.tabScope.width();
                        // if (contenerWidth > $(window).height()) {
                        //     contenerWidth = $(window).height();
                        // }
                        sett.tabScope.width(contenerWidth);
                        sett.tabScope.height(contenerWidth);
                        $(canvas).attr('width', contenerWidth + 'px');
                        $(canvas).attr('height', contenerWidth + 'px');
                        redraw();

                    };

                    //##############################
                    //EVENTS HANDLERS
                    //##############################
                    var bindUiEvents = function () {
                        //Start scraching
                        sett.tabScope.on('mousedown touchstart', function (e) {
                            if (isDisable) return false;
                            var pos = pointerEventToXY(e);
                            marker.pos.x = pos.x;
                            marker.pos.y = pos.y;
                            marker.isActive = true;
                            context.clearRect(0, 0, canvas.width, canvas.height);
                            drawScrachedLines();
                            drawLine(marker.pos, pos);
                        });

                        //End scraching
                        sett.tabScope.on('mouseup touchend mouseleave', function (e) {

                            marker.isActive = false;
                            if (e.type == "mouseleave") {
                                context.clearRect(0, 0, canvas.width, canvas.height);
                                drawScrachedLines();
                                return false;
                            }
                            CrossWordService.getScrachedWord(marker.pos, pointerEventToXY(e));
                            context.clearRect(0, 0, canvas.width, canvas.height);
                            drawScrachedLines();
                        });

                        //Do scraching
                        sett.tabScope.on('mousemove touchmove', function (e) {
                            e.preventDefault();
                            if (!marker.isActive) return false;
                            context.clearRect(0, 0, canvas.width, canvas.height);

                            var pos = pointerEventToXY(e);
                            drawScrachedLines();
                            drawLine(marker.pos, pos);
                        });

                        /*sett.button.on('click', function(e) {
                            e.preventDefault();
                            CrossWordService.finishCrossWord();
                            dataLayers.crosswordSend();
                        })*/
                    };

                    //##############################
                    //PUBLIC METHODS
                    //##############################

                    //constructor
                    function ScratchWords(settings) {
                        init(settings);
                    }

                    var scratchWords = new ScratchWords({});

                    //function declaration, which will be called in controller
                    //scope.control = scope.control || {};
                    var crosswordControl = {};

                    crosswordControl.turnOn = function () {
                        bindUiEvents();
                    }

                    crosswordControl.redraw = function () {
                        redraw();
                    }

                    //controller
                    var crosswordQueryStr = (window.location.href).substr((window.location.href).lastIndexOf('/') + 1),
                        crosswordUuidStr = crosswordQueryStr.indexOf("?") > 0 ? crosswordQueryStr.split("?")[0] : crosswordQueryStr,
                        crosswordUuid = crosswordQueryStr ? crosswordUuidStr : null;
                    $('html, body').animate(
                        {
                            scrollTop: $('.wordsearch-page').offset().top
                        },
                        2000
                    );
                    // Private variables
                    var kraveHorrorWinUrl = '/win';
                    var kraveHorrorLoseUrl = locale + '/lose';
                    var kraveHorrorUrl = locale + '/Error',
                        // Public variables
                        isStarted = false,
                        isFinishEnable = false;
                    // This variable will be pass to directive
                    // Private methods
                    document.getElementById('crossword').onselectstart = function () {
                        return false;
                    };
                    document.getElementById('crossword').onmousedown = function () {
                        return false;
                    };
                    // Public methods
                    CrossWordService.timerecorder();
                    CrossWordService.setFinishCallback(
                        function (data) {
                        },
                        function (reason) {
                            window.location.href = ('/' + locale + '/error');
                        },
                        webStorageService.getSessionData('servToken')
                    );
                    if (typeof crosswordControl.turnOn === 'function')
                        crosswordControl.turnOn();
                    if (typeof crosswordControl.redraw === 'function')
                        crosswordControl.redraw();
                } else {
                    kraveHorrorUrl = locale + '/Error';
                }
            })
            .catch(function (error) {
                HandleError(error.response);
            });
        // pass callback function to the service
        CrossWordService.isFinishEnable(function () {
            // $apply(function () {
            isFinishEnable = true;
            // });
        });
    }, [])

    let pageState = webStorageService.getSessionData('createPageState');
    // pageState = 'Crossword';
    if (pageState == '' || pageState == undefined || pageState == null) {
        window.location = '/' + locale + '/Home';
    } else if (pageState != 'Crossword') {
        return <Redirect to={'/' + locale + '/' + pageState} />
    }

    // Initial state of letters
    var letters = [],
        Crossword = [],
        // Initial state of words
        words = [],
        Words = [],
        wordMustBeChecked = true,
        isFinishEnable = function () { },
        coordinatesToReturn = {},
        lockCrossword = false,
        CrossWordService = {
            finishCb: null,
            finishErrorCb: null,
            token: null,
            timerecorder: (function () {
                var startdate = new Date();
                var set = false;
                var end = false;
                var enddate = new Date();
                return function (state) {
                    var ResultSolutionTimeSeconds = enddate - startdate;
                    if (!set) {
                        startdate = new Date();
                        set = true;
                        return startdate;
                    }
                    if (state == 'stop' && !end) {
                        enddate = new Date();
                        end = true;
                        return enddate;
                    }
                    startdate = ''; set = false; end = false; enddate = '';
                    return ResultSolutionTimeSeconds;
                };
            })(),
            haveFinalDetails: function () {
                return Crossword.length > 0 && Words.length > 0;
            },
            setLetters: function (lettersToSet) {
                try {
                    Crossword = JSON.parse(lettersToSet);
                } catch (e) {
                    // Console.log("Bad letters format from backend");
                }
            },
            setWords: function (wordsToSet) {
                try {
                    var wordsArr = JSON.parse(wordsToSet);
                    Words = [];
                    for (var i = 0; i < wordsArr.length; i++) {
                        if (locale.toLowerCase() == "ka_ge") {
                            Words.push({
                                word: wordsArr[i].toLowerCase(),
                                isScratched: false
                            });
                        }
                        else {
                            Words.push({
                                word: wordsArr[i].toUpperCase(),
                                isScratched: false
                            });
                        }
                    }
                } catch (e) {
                    // Console.log("Bad words format from backend");
                }
            },
            getLetters: function () {
                return letters;
            },
            getWords: function () {
                return words;
            },
            isFinishEnable: function (cb) {
                isFinishEnable = cb;
            },
            finishCrossWord: function () {
                //   var token = this.token;
                //   var result = $q.defer();
                var answers = [];
                $('.ng-isolate-scope').removeClass("hide-loader");
                for (var k in coordinatesToReturn) {
                    answers.push({
                        Row: k,
                        Columns: coordinatesToReturn[k]
                    });
                }
                API.postCrossword(
                    { answers: answers, SolutionTimeSeconds: CrossWordService.timerecorder() },
                    webStorageService.getSessionData('servToken')
                )
                    .then(function (data) {
                        dataLayers().wordSearchComplete();
                        if (data.response.RedirectUrl.toLowerCase().indexOf('thankyou') > -1) {
                            // (locale.toLowerCase() == 'mk_mk') ? dataLayers().orderData(16) : dataLayers().orderData(97);
                            var winnerObj = {
                                winner: 97,
                                isExtendedPhase: data.response.ExtendedPhase,
                                refCode: data.response.RefCode
                            }
                            if(!data.response.ExtendedPhase){
                                dataLayers().orderData();
                            }
                            webStorageService.setSessionData('createPageState', 'Thankyou');
                        }
                        history.push({ pathname: data.response.RedirectUrl, state: winnerObj });
                        pageScroll();

                    })
                    .catch(function (error) {
                        HandleError(error.response);
                    });
            },
            setFinishCallback: function (fn, fnError, token) {
                this.finishCb = fn;
                this.finishErrorCb = fnError;
                this.token = token;
            },
            start: null,
            getScrachedWord: null
        },
        customMerg = function (obj1, obj2, obj3) {
            if (typeof obj2 !== 'object') {
                if (!obj1[obj2]) {
                    obj1[obj2] = [];
                }
                obj1[obj2].push(obj3);
                return false;
            }
            for (var i in obj2) {
                if (!obj1[i]) {
                    obj1[i] = [];
                }
                for (var j = 0; j < obj2[i].length; j++) {
                    if (obj1[i].indexOf(obj2[i][j]) == -1) {
                        obj1[i].push(obj2[i][j]);
                    }
                }
            }
        };
    // Detect if scrached word is correct
    CrossWordService.getScrachedWord = function (posStart, posEnd) {
        var scrachedWord = '';
        var lettersCoordinates = {};
        if (posStart.x == posEnd.x) {
            var start = posStart.y > posEnd.y ? posEnd.y : posStart.y;
            var end = posStart.y > posEnd.y ? posStart.y : posEnd.y;
            for (var i = start; i <= end; i++) {
                scrachedWord += letters[i][posStart.x];
                customMerg(lettersCoordinates, i, posStart.x);
            }
        } else if (posStart.y == posEnd.y) {
            var start = posStart.x > posEnd.x ? posEnd.x : posStart.x;
            var end = posStart.x > posEnd.x ? posStart.x : posEnd.x;
            for (var i = start; i <= end; i++) {
                scrachedWord += letters[posStart.y][i];
                customMerg(lettersCoordinates, posStart.y, i);
            }
        } else if (Math.abs(posStart.x - posEnd.x) == Math.abs(posStart.y - posEnd.y)) {
            var start = posStart.x > posEnd.x ? posEnd : posStart;
            var end = posStart.x > posEnd.x ? posStart : posEnd;
            for (var i = 0; i <= end.x - start.x; i++) {
                if (start.y < end.y) {
                    scrachedWord += letters[start.y + i][start.x + i];
                    customMerg(lettersCoordinates, start.y + i, start.x + i);
                } else {
                    scrachedWord += letters[start.y - i][start.x + i];
                    customMerg(lettersCoordinates, start.y - i, start.x + i);
                }
            }
        }
        if (wordMustBeChecked) {
            if (
                checkWord(scrachedWord, posStart, posEnd, function () {
                    customMerg(coordinatesToReturn, lettersCoordinates);
                })
            ) {
                // Nothing
            }
        } else if (scrachedWord != '') {
            words.push({
                word: scrachedWord,
                isScratched: true,
                start: {
                    x: posStart.x,
                    y: posStart.y
                },
                end: {
                    x: posEnd.x,
                    y: posEnd.y
                }
            });
            customMerg(coordinatesToReturn, lettersCoordinates);
            // isFinishEnable();
        }
    };
    // Detect if scrached word is on the words list

    var checkWord = function (word, posStart, posEnd, cb) {
        var count = 0,
            result = false,
            start,
            end,
            starty;
        for (var i = 0; i < words.length; i++) {
            if (
                !words[i].isScratched &&
                (words[i].word.trim() == word ||
                    words[i].word.trim() ==
                    word
                        .split('')
                        .reverse()
                        .join(''))
            ) {
                words[i].isScratched = true;
                var wordChecked = words[i].word.trim().toLowerCase();
                $('.' + wordChecked)
                    .addClass('wodrIsChecked')
                    .append('<span class="point"></span>');
                words[i].start = {
                    x: posStart.x,
                    y: posStart.y
                };
                words[i].end = {
                    x: posEnd.x,
                    y: posEnd.y
                };
                count++;
                if (posStart.x == posEnd.x) {
                    start = Math.min(posStart.y, posEnd.y);
                    end = Math.max(posStart.y, posEnd.y);
                    for (var j = start; j <= end; j++) {
                        $(
                            'tr:nth-child(' + (j + 1) + ') td:nth-child(' + (posStart.x + 1) + ')'
                        ).css({ color: '#fff' });
                    }
                } else if (posStart.y == posEnd.y) {
                    start = Math.min(posStart.x, posEnd.x);
                    end = Math.max(posStart.x, posEnd.x);
                    for (var j = start; j <= end; j++) {
                        $(
                            'tr:nth-child(' + (posStart.y + 1) + ') td:nth-child(' + (j + 1) + ')'
                        ).css({ color: '#fff' });
                    }
                } else {
                    var z = 1;
                    start = Math.min(posStart.x, posEnd.x);
                    end = Math.max(posStart.x, posEnd.x);
                    starty = Math.min(posStart.y, posEnd.y);
                    if (
                        (posStart.x > posEnd.x && posStart.y > posEnd.y) ||
                        (posStart.x < posEnd.x && posStart.y < posEnd.y)
                    ) {
                        for (var j = start; j <= end; j++) {
                            $(
                                'tr:nth-child(' + (starty + z) + ') td:nth-child(' + (j + 1) + ')'
                            ).css({ color: '#fff' });
                            z++;
                        }
                    } else {
                        for (var j = end; j >= start; j--) {
                            $(
                                'tr:nth-child(' + (starty + z) + ') td:nth-child(' + (j + 1) + ')'
                            ).css({ color: '#fff' });
                            z++;
                        }
                    }
                }
                result = true;
            } else if (words[i].isScratched) {
                count++;
            }
        }
        if (word.isScratched) {
        }
        if (result) {
            cb();
        }
        // If(count==words.length){

        if (count == 10) {
            // isFinishEnable();
            CrossWordService.timerecorder('stop');
            if (lockCrossword === false) {
                CrossWordService.finishCrossWord();
                lockCrossword = true;
            }
        }
        return result;
    };
    CrossWordService.start = function () {
        letters = Crossword;
        words = Words;
    };

    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_CrosswordPageTitle + ' | ' + translations.CampaignName}
                description={translations.MetaContent}
                keywords=''
            />
            <section className="wordsearch-page">
                <div className="crossword">
                    <h1 dangerouslySetInnerHTML={{__html:translations.WordSearchPageTitle}}></h1>
                    <div className="hiding" role="alert" >{translations.WordSearchPageTitle}</div>
                    {/* <p className="subhead">{translations.WordSearchSubHeading}</p> */}
                    <div className="col-md-3 col-sm-3 col-xs-2"></div>
                    <div className="two-col clearfix">
                        <div className="col-1 cross-word" id="crossword" cross-word="" control="crosswordControl">
                            <div className="tabContainer">
                                <canvas></canvas>

                                <table>
                                    <tbody>

                                        {
                                            crossletters.map(function (row, index) {

                                                return (
                                                    <tr key={index}>

                                                        {
                                                            row.map(function (value, vindex) {
                                                                return (
                                                                    <td key={vindex}>{row[vindex]}
                                                                    </td>
                                                                )
                                                            })
                                                        }

                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-2 words-to-find">
                            <div className="tooltip-top-left">
                                <h3>{translations.WordsToFind}</h3>
                                <p className="ng-binding checked-word" dangerouslySetInnerHTML={{ __html: findwords }}></p>
                            </div>
                        </div>
                    </div>
                    <div ng-activity-indicator="Circled" skip-ng-show="yes" ng-show="loading" className="ng-isolate-scope ng-hide loader hide-loader">
                        <span className="ai-circled ai-indicator ai-grey-spin"></span>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Wordsearch;