import React, { useContext } from 'react';
import { Redirect, useLocation, useHistory, Link } from "react-router-dom";
import * as webStorageService from '../Utility/WebStorageFactory';
import { locale, MetaTags } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
import dataLayers from '../Utility/DataLayers';
import Button from '../Partials/Button';
const Thankyou = () => {
    const { translations } = useContext(JsonContext);
    const location = useLocation();
    let pageState = webStorageService.getSessionData('createPageState');
    //pageState = 'Thankyou';

    if (pageState == '' || pageState == undefined || pageState == null) {
        window.location = '/' + locale + '/Home';
    } else if (pageState != 'Thankyou') {
        return <Redirect to={'/' + locale + '/' + pageState} />
    }

    webStorageService.removeSessionData('createPageState');
    document.querySelector('body').setAttribute('pagename', 'Thankyou');


        //   var winnerCode = 1,
        //     isExtendedPhase = false,
        //     refCode = '';
    var winnerCode = location.state.winner,
    isExtendedPhase = location.state.isExtendedPhase,
    refCode = location.state.refCode ? location.state.refCode : '';
    var winCategory, WinPageHeading, WinPageSubHeading,WinPageDesc, WinPageDesc1, WinPageDesc2, WinPageDesc3, WinPageDesc4;
    WinPageHeading = translations.ThankYouPageHeading_wm;
    WinPageSubHeading = translations.ThankYouPageDescription_wm;
    WinPageDesc1 = translations.ThankYouPageDescription1_wm;
    WinPageDesc2 = translations.ThankYouPageDescription2_wm;
    if (isExtendedPhase) {
        winCategory = 'extended'
        WinPageHeading = translations.PrizeDrawThankyouHeading;
        WinPageSubHeading = translations.PrizeDrawThankyouDescription;
        WinPageDesc1 = translations.PrizeDrawThankyouDescription1;
        WinPageDesc2 = '';
        WinPageDesc3 = '';
        WinPageDesc4 = '';
        dataLayers().orderData(97);
    }

 
    var setThankyouContent = function () {
        switch (winnerCode) {
            case 0:
            case 1:
            case 2:
            case 3:
            case -16:
                winCategory = 'Lose';
                WinPageHeading = translations.ThankYouPageHeading_wm;
                
                WinPageDesc1 = translations.ThankYouPageDescription1_wm;
                WinPageDesc2 = translations.ThankYouPageDescription2_wm;
                WinPageDesc3 = translations.ThankYouPageDescription3_wm;
                WinPageDesc4 = translations.ThankYouPageDescription4_wm;
                break;
            case 97:
                winCategory = 'Crossword';
                WinPageHeading = translations.ThankYouPageHeading_tos;
                WinPageSubHeading = translations.ThankyouPageDescriptionTos;
                WinPageDesc1 = translations.ThankyouPageDescription1_tos;
                WinPageDesc2 = '';
                break;
            default:
                // $location.path(lang + '/Home');
                break;
        }
    }
    var setThankyouContentIT = function () {
        WinPageDesc3 = '';
        WinPageDesc4 = '';
        switch (winnerCode) {
            case 0:
            case -16:
                winCategory = 'Lose';
                WinPageHeading = translations.ThankYouPageHeading_wm;
                WinPageDesc1 = translations.ThankYouPageDescription1_wm;
                WinPageDesc2 = translations.ThankYouPageDescription2_wm;
                WinPageDesc3 = translations.ThankYouPageDescription3_wm;
                WinPageDesc4 = translations.ThankYouPageDescription4_wm;
                break;

            case 1:
                winCategory = 'Win'
                WinPageHeading = translations.CoreKelloggs_Congratulations;
                WinPageSubHeading = '';
                WinPageDesc1 = translations.Tier1EmailDescription;
                WinPageDesc2 = translations.Tier1_WinPageDescription + " "+ translations.Tier1EmailDescription1;
                break;
            default:
                // $location.path(lang + '/Home');
                break;
        }
    }
    if (!isExtendedPhase && locale == 'it_IT') {
        setThankyouContentIT();
    } else if (!isExtendedPhase) {
        setThankyouContent();
    }
    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_ThankyouPageTitle + ' | ' + translations.CampaignName}
                description={translations.MetaContent}
                keywords=''
            />
             <section className="thankyou-page">
                <h1 dangerouslySetInnerHTML={{__html :WinPageHeading }}></h1>
                <div role="alert" className="hiding" >{WinPageHeading}</div>
               {WinPageSubHeading && <p>{WinPageSubHeading}</p>}
                

                {WinPageDesc1 && <p>{WinPageDesc1}</p>}
                {WinPageDesc2 && <p>{WinPageDesc2}</p>}
                {WinPageDesc3 && <p>{WinPageDesc3}</p>}
                {WinPageDesc4 && <p>{WinPageDesc4}</p>}
                    {
                        winCategory == 'Win' && <div><p className="ref-copy" dangerouslySetInnerHTML={{ __html: translations.CoreKelloggs_ReferenceNumberCopy }}></p>
                            <p className="ref-copy">{refCode}</p>
                        </div>
                    }

                <div className="cta-container">
                    <Button/>
                </div>
            </section>
        </>
    )
}

export default Thankyou;