import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation, } from 'react-router-dom';
import { locale } from '../Utility/Global';
import { localeList } from '../Utility/ConfigData.json';
import JsonContext from '../Utility/JsonProvider';
import * as webStorageService from '../Utility/WebStorageFactory';
const Header = () => {

    const { translations } = useContext(JsonContext);
    const history = useHistory();
    const location = useLocation();

    const [toggle, settoggle] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const showLanguageList = (e) => {
        settoggle(!toggle);
        e.preventDefault();
    }

    useEffect(() => {
        // on Load
        if (window.location.pathname.toLowerCase().indexOf('home') > -1 || window.location.pathname.toLowerCase().indexOf('comingsoon') > -1 || window.location.pathname.toLowerCase().indexOf('landing') > -1) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
        //route change handling
        history.listen((location) => {
            if (location.pathname.toLowerCase().indexOf('home') > -1 || window.location.pathname.toLowerCase().indexOf('comingsoon') > -1 || window.location.pathname.toLowerCase().indexOf('landing') > -1) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        });

    }, [history,location]);
    const showCountry = (e, value, name) => {
        e.preventDefault();
        webStorageService.removeSessionData('countryName');
        let redirectTo = window.location.pathname.toLowerCase().indexOf('landing') > -1 ? '/Landing' : '/Home';
        webStorageService.setSessionData('countryName', name);
        window.location = "/" + value + redirectTo;
    }

    //find current country and locale according to url
    let currObj = localeList.find(o => o.locale === locale);
    let currCountry = currObj.countryName;
    let currLocale = currObj.locale;

    //find current country and locale according to session

    if(webStorageService.getSessionData('countryName') != undefined ){
        let temp = webStorageService.getSessionData('countryName');
        let tempLocale = localeList.find(o => o.countryName === temp).locale;
        if(tempLocale!=currLocale){
            webStorageService.removeSessionData('countryName');
        }else{
            currCountry = webStorageService.getSessionData('countryName')
        }
    }

    return (
        <>
            <header id="Header">
                {/* country selector dropdown */}
                {isVisible &&
                    <div className="swicth_country">
                        <span id="CountryRegion">{translations.CountrySelectorLabel} </span>
                        <div className="languageSelectorWrapper">
                            <div>
                                <a href="#" className="selected-lang" aria-expanded={toggle ? "true" : "false"} onClick={showLanguageList} title={translations.CoreKelloggs_CountrySelectorLinkTitle} aria-labelledby="CountryRegion">
                                    <span className="wrapper-name" style={{ backgroundColor: toggle ? '#bfd2f0e6' : '#434343' }}>
                                        <span className="desktop" style={{ color: toggle ? '#242424' : '#ffffff' }}  >{currCountry}</span>
                                        <span className={toggle ? 'uparrow ddarrow' : 'downarrow ddarrow'}></span>
                                    </span>
                                </a>

                            </div>
                            <ul className="list-country" style={{ display: toggle ? "block" : "none" }}>
                                {
                                    localeList.map((data, index) => {
                                        if (data.showInCountryDropDown) {
                                            return <li key={data.countryName} datacntry= {data.countryName} ><a href='#' onClick={(e) => showCountry(e, data.locale, data.countryName)} title={translations.CoreKelloggs_SelectCountryNameLinkTitle}>{data.countryName}</a></li>
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                }
                <picture picturefill="true">
                    <source srcSet={'/Content/img/header-visuals/nba2023-header-desktop-' + locale +  '.png'} media="only screen and (min-width: 768px)" />
                    <source srcSet={'/Content/img/header-visuals/nba2023-header-mobile-' + locale + '.png'} media="only screen and (max-width: 767px)" />
                    <img src={'/Content/img/header-visuals/nba2023-header-mobile-' + locale + '.png'} alt={translations.HeaderAltText} />
                </picture>
            </header>
        </>
    )
}

export default Header;