import { locale } from './Global';

const dataLayers = () => {
    return {
        init: _initDataLayers,
        gtm: _initGTM,
        signInData: _SuccessfulSignIn,
        registerData: _SuccessfulAccountRegistration,
        orderData: _OderComplete,
        orderDataIT: _OderCompleteIT,
        imageUpload: _ImageUpload,
        selectSplitLocale: _SelectSplitLocale,
        packCodeData: _PackCodeSubmission,
        backToHP: _BackToHP,
        socialData: _SocialShare,
        wordSearchComplete: _WordSearchComplete,
        entryLimit: _entryLimit,
        winLimit: _winLimit,
        errorCodeEvent: _ErrorCode,
    };
    function _initDataLayers(settings) {
        var script = document.createElement('script'),
            body = document.getElementsByTagName('body'),
            lang = (locale == 'be' || locale == 'ch') ? settings.Culturecode.split('-')[0] : locale.split('_')[0].toLowerCase(),
            country = (locale == 'be' || locale == 'ch') ? locale : locale.split('_')[1].toUpperCase();
        script.type = 'text/javascript';
        script.id = 'DataLayer';
        script.text = 'var dataLayer = [{\'language\': \'' + lang + '\', \'locale\': \'' + country + '\', \'brand\': \'Kelloggs\', \'campaign\': \'NBA 2023\'}];';
        document.body.prepend(script);
    }
    function _initGTM(translations) {
        var script = document.createElement('script'),
            noscript = document.createElement('noscript'),
            iframe = document.createElement('iframe'),
            body = document.getElementsByTagName('body'),
            dl = document.getElementById('DataLayer');
        script.type = 'text/javascript';
        script.text = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\'//www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})(window,document,\'script\',\'dataLayer\',\'GTM-5VF3\');';
        iframe.src = '//www.googletagmanager.com/ns.html?id=GTM-5VF3';
        iframe.height = 0;
        iframe.width = 0;
        iframe.style = 'display:none;visibility:hidden';
        iframe.name = 'grm-iframe';
        iframe.title = translations.GtmTitle;
        Node.prototype.insertAfter = function (newNode) {
            if (this.nextSibling) {
                return this.parentNode.insertBefore(newNode, this.nextSibling);
            } else {
                return this.parentNode.appendChild(newNode);
            }
        };
        dl.insertAfter(noscript);
        noscript.appendChild(iframe);
        noscript.insertAfter(script);
    }
    function _addData(obj) {
        if (!obj || Object.keys(obj).length <= 0) { return; }
        if (window.dataLayer) {
            window.dataLayer.push(obj);
        }
        return;
    }
    function _PackCodeSubmission() {
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'Pack Code Submission',
            'label': 'Success'
        };
        _addData(obj);
        return;
    }
    function _ImageUpload() {
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'Image Upload(Italy)',
            'label': 'Success'
        };
        _addData(obj);
        return;
    }
    function _SelectSplitLocale(language_region) {
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'Language Page',
            'label': language_region
        };
        _addData(obj);
    }
    function _WordSearchComplete() {
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'WordSearch Entry Complete',
            'label': 'Prize TBC'
        };

        _addData(obj);
        return;
    }
    function _SuccessfulSignIn(userId, city, country, optIn, label) {
        var updated_label = (label == 'Social') ? label : 'On-site';
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'Account Log In',
            'label': updated_label,
            'userID': userId ? userId : ''
            /*'city': city ? city : '',
            'country': country ? country : '',
            'optIn': optIn ? 'Yes' : 'No'*/
        };
        _addData(obj);
    }
    function _SuccessfulAccountRegistration(userId, city, country, optIn, label) {
        var updated_label = (label == 'Social') ? label : 'On-site';
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'Account Registration',
            'label': updated_label,
            'userID': userId ? userId : '',
            'optIn': optIn ? 'Opt In' : 'Opt Out'
        };
        _addData(obj);
    }
    function _OderComplete() {
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'Entry Complete',
            'label': 'Prize TBC'
        };
        _addData(obj);
    }
    function _OderCompleteIT(prizeNumber) {
        var label;
        switch (prizeNumber) {
            case 1:
                label = 'USA Trip'
                break;
            case 2:
                label = 'Hoop'
                break;
            case 3:
                label = 'Gift Card 50'
                break;
            case 4:
                label = 'Gift Card 25'
                break;
            case 5:
                label = 'Sweatbands'
                break;
            case 6:
                label = 'Stressball'
                break;    
            default:
                // $location.path(lang + '/Home');
                break;
        }
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'Entry Complete',
            'label': label
        };
        _addData(obj);
    }
    function _SocialShare(label) {
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'Social Share',
            'label': label ? label : ''
        };
        _addData(obj);
    }
    function _entryLimit() {
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'Entry Limit',
            'label': 'Success'
        };
        _addData(obj);
    }
    function _winLimit() {
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'Win Limit',
            'label': 'Success'
        };
        _addData(obj);
    }
    function _BackToHP() {
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'Return To Homepage',
            'label': 'Success'
        };
        _addData(obj);
    }
    function _ErrorCode(url, errorCode) {
        var obj = {
            'event': 'KE1.event',
            'category': 'NBA 2023',
            'action': 'Error Code',
            'label': url ? url : '',
            'errorCode': errorCode ? errorCode : ''
        };
        _addData(obj);
    }
}

export default dataLayers;