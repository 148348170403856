import React, { useContext } from 'react';
import { Redirect } from "react-router-dom";
import { MetaTags, locale } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
import * as webStorageService from '../Utility/WebStorageFactory';
import Button from '../Partials/Button';
const Comingsoon = () => {
    const { translations } = useContext(JsonContext);
    document.querySelector('body').setAttribute('pagename', 'Comingsoon');
    let pageState = webStorageService.getSessionData('createPageState');
    // pageState = 'Comingsoon';
    if (pageState == '' || pageState == undefined || pageState == null) {
        window.location = '/' + locale + '/Home';
    } else if (pageState != 'Comingsoon') {
        return <Redirect to={'/' + locale + '/' + pageState} />
    }


    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_ComingSoonPageTitle + ' | ' + translations.CampaignName}
                description={translations.MetaContent}
                keywords=''
            />
            <section className="comingsoon-page">
                <div className="content col-one">
                    <h1>{translations.ComingSoonHeading}</h1>
                    <p dangerouslySetInnerHTML={{ __html: translations.ComingSoonDescription }}></p>
                    <a href={translations.CoreKelloggs_PromotionClosedCtaLink} className="button active" title={translations.CoreKelloggs_PromotionClosedCtaLinkTitle}>
                    <span className="btn-copy">{translations.CoreKelloggs_PromotionClosedCta}</span>
                </a>
                </div>
            </section>
        </>
    )
}

export default Comingsoon;