import React, { useContext } from "react";
import { MetaTags, $ } from "../Utility/Global";
import * as webStorageService from "../Utility/WebStorageFactory";
import { Link } from "react-router-dom";
import { locale } from "../Utility/Global";
import JsonContext from "../Utility/JsonProvider";

const Landing = () => {
  const { translations } = useContext(JsonContext);
  webStorageService.removeSessionData("codes");
  webStorageService.removeSessionData("createPageState");
  webStorageService.removeSessionData("btnObj");
  webStorageService.removeSessionData("shippingBtnObj");
  webStorageService.removeSessionData("receiptObj");
  webStorageService.removeSessionData("popShow");

  document.querySelector("body").setAttribute("pagename", "Landing");
  const _intervalG = setInterval(function () {
    logout();
  }, 300);
  function logout() {
    if (window.gigya != undefined) {
      window.gigya.accounts.logout();
      clearInterval(_intervalG);
    }
  }
  return (
    <>
      <MetaTags
        title={
          translations.Landingpagetitle +
          " | " +
          translations.CampaignName
        }
        description={translations.MetaContent}
        keywords=""
      />
      <section className="landing-page">
        <h1>{translations.LandingPage_header}</h1>
        <p>{translations.LandingPage_description}</p>
        <p>{translations.LandingPage_description1}</p>
        <Link
          to={'/' + locale + '/Home'}
          className="button"
          title={translations.LandingPageCta1_title}
        >
          <span className="btn-copy ">{translations.LandingPage_cta1}</span>
        </Link>
        {
          (locale == 'es_ES' || locale == 'fr_BE' || locale == 'nl_BE' || locale == 'pt_PT' || locale == 'nl_NL' ||  locale == 'fr_FR') &&  <a
          href={translations.Landingpagesnacksctalink}
          className="button"
          title={translations.LandingpagesnacksctaTitle}
        >
          <span className="btn-copy ">{translations.Landingpagesnacksctatext}</span>
        </a>
        }
     
        <a
          href={translations.LandingPageCta2_link}
          className="button"
          title={translations.LandingPageCta2_title}
        >
          <span className="btn-copy ">{translations.LandingPage_cta2}</span>
        </a>
        <Link
          to={'/' + locale + '/AboutNBAJRLeague'}
          className="button"
          target="_blank"
          title={translations.LandingPageCta3_title}
        >
          <span className="btn-copy ">{translations.LandingPage_cta3}</span>
        </Link>
      </section>
    </>
  );
};

export default Landing;
