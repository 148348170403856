import {$} from '../../Utility/Global'

$(window).on('load',function(){
    var serviceURL = (typeof window.JS_ServiceURL !== 'undefined')?window.JS_ServiceURL:'https://services.kelloggs.com',
        base = window.location.pathname.split('/')[1],
        siteURL = window.location.origin + (base.length > 0 ? "/" + base : "");
		if (siteURL.indexOf('stage-') >= 0) {
           /*commented stage as per mindtree ref#1076472*/
            serviceURL = "//gammastage.services.kelloggs.com";
        }
       $.ajax({
                      url: serviceURL+'/bin/ccquerybuilder.jsonp?propertyName=sitepath&propertyValue='+siteURL,
                      dataType: 'jsonp',
                      //jsonp: false,
                      jsonpCallback: 'getAlert',
                      success: function(data){
                           //console.log(data); 
                      },
                      complete: function() {
                           //alert('complete');
                      },
                      error: function (request, error, errorThrown) {
                           //console.log(error, errorThrown);
                      }
       });
});
window.getAlert = function(jsonObject) {
       var json = JSON.parse(jsonObject), i, msg = "";
       for (i=0;i<json.hits.length;i++)
       {
           // check this condition
             var ser=json.hits[i].detMsgURL;
             // check the URL whether its a day based application or non-day based application
             var alertImgURL=json.hits[i].alertImgURL;
             var destURL=json.hits[i].detMsgURL;
             var shortMsg=json.hits[i].shortMsg;
             msg+='<div class="errorwrap"><p><img style="vertical-align:middle;margin-top:0px" src="'+alertImgURL+'" height="20" width="20" alt="Alert Image" />&nbsp;<a class="error_msg" href=\''+destURL+'\' target=\'_blank\'> '+shortMsg+'</a></div>';
       }
       if(document.getElementById('error_wp').innerHTML!=null)
       document.getElementById('error_wp').innerHTML=msg;
}