import axios from 'axios';
import { locale } from './Global';

export const checkUpcCodes = async (dataObj, token) => {
    if (!locale || !dataObj) { return; }
    let urlPath = '/api/' + locale + '/redemption/validate-codes';
    if (locale.toLowerCase() == 'it_it') {
        urlPath = '/api/' + locale + '/redemption/validate-codesIT'
    }
    if (locale.toLowerCase() == 'it_it') {
        const response = await axios(urlPath, {
            method: 'POST',
            headers: { 
                'Content-Type': undefined, 
                enctype: 'multipart/form-data', 
                'redemption-token': token 
            },
            data: dataObj,
        });
        return response.data;
    } else {
        const response = await axios(urlPath, {
            method: 'POST',
            headers: { 'redemption-token': token },
            data: { 'codes': dataObj },
        });
        return response.data;
    }
};

export const checkUser = async objToSend => {
    if (!locale || !objToSend) { return; }
    var keys = '',
        urlPath = '/api/' + locale + '/redemption/user';
    for (keys in objToSend) {
        if (objToSend[keys] === '') {
            return;
        }
    }
    var dataObj = {
        'accessToken': objToSend.accessToken,
        'userName': objToSend.userName,
        'profileId': objToSend.profileId,
        'userAgent': objToSend.userAgent,
        'QuestionLegal': objToSend.QuestionLegal,
        'allParamsEncrypted': objToSend.allParamsEncrypted,
        'NbaConsent': objToSend.NbaConsent
    }
    const response = await axios(urlPath, {
        method: 'POST',
        // headers: {
        //     'redemption-token': objToSend['redemption-token']
        // },
        data: dataObj,
    });
    return response.data;

};
export const russiaOrder = async shippingInfoObj => {
    if (!locale || !shippingInfoObj) { return; }
    var urlPath = '/api/' + locale + '/redemption/userKPS';
    var dataObj = {
        'shippingDetails': shippingInfoObj.shippingDetails,
        'UserName':shippingInfoObj.UserName,
        'accessToken': shippingInfoObj['redemption-token'],
        'profileId': -1,
        'userAgent': shippingInfoObj.ua,
        'allParamsEncrypted': shippingInfoObj['redemption-token']
    }
    const response = await axios(urlPath, {
        method: 'POST',
        headers: {
            'redemption-token': shippingInfoObj['redemption-token']
        },
        data: dataObj,
    });
    return response.data;

};
export const verifyCaptchaEnterCode = async data => {
    if (!locale || !data) { return; }
    var urlPath = '/api/' + locale + '/redemption/authenticate-validate-codes';
    const response = await axios(urlPath, {
        method: 'POST',
        headers: {
            'redemption-token': data.redemptionToken
        },
        data: { EncodedResponse: data.token },
    });
    return response.data;

};
export const verifyCaptcha = async data => {
    if (!locale || !data) { return; }
    var urlPath = '/api/' + locale + '/redemption/authenticate';
    const response = await axios(urlPath, {
        method: 'POST',
        headers: {
            'redemption-token': data.redemptionToken
        },
        data: { EncodedResponse: data.token },
    });
    return response.data;

};

export const orderAward = async (shippingInfoObj) => {
    if (!locale || !shippingInfoObj) { return; }
    let urlPath = '/api/' + locale + '/redemption/init-order';
        const response = await axios(urlPath, {
            method: 'POST',
            headers: { 'redemption-token': shippingInfoObj['redemption-token'] },
            data: {'shippingDetails': shippingInfoObj.shippingDetails}
        });
        return response.data;
};
export const getPuzzle = async (token) => {
    if (!locale) { return; }
    let urlPath = '/api/' + locale + '/redemption/get-skill';
        const response = await axios(urlPath, {
            method: 'POST',
            headers: { 'redemption-token': token }
        });
        return response.data;
};
export const postPuzzle = async (data,token) => {
    if (!locale || !data) { return; }
    let urlPath = '/api/' + locale + '/redemption/validate-skill';
        const response = await axios(urlPath, {
            method: 'POST',
            headers: { 'redemption-token': token },
            data : data
        });
        return response.data;
};
export const getCrossword = async (token) => {
    if (!locale) { return; }
    let urlPath = '/api/' + locale + '/redemption/get-crossword';
        const response = await axios(urlPath, {
            method: 'POST',
            headers: { 'redemption-token': token }
        });
        return response.data;
};
export const postCrossword = async (data,token) => {
    if (!locale || !data) { return; }
    let urlPath = '/api/' + locale + '/redemption/validate-crossword';
        const response = await axios(urlPath, {
            method: 'POST',
            headers: { 'redemption-token': token },
            data : data
        });
        return response.data;
};
