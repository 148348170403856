import React, { useContext } from 'react';
import { Redirect, useHistory, useLocation } from "react-router-dom";
import * as webStorageService from '../Utility/WebStorageFactory';
import { locale, MetaTags } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
import dataLayers from '../Utility/DataLayers';
import Button from '../Partials/Button';
const Error = () => {
    const { translations } = useContext(JsonContext);
    const history = useHistory();
    const location = useLocation();

    let pageState = webStorageService.getSessionData('createPageState');
    pageState = 'Error';
    if (pageState == '' || pageState == undefined || pageState == null) {
        window.location = '/' + locale + '/Home';
    } else if (pageState != 'Error') {
        return <Redirect to={'/' + locale + '/' + pageState} />
    }


    document.querySelector('body').setAttribute('pagename', 'Error');
    let ErrorKey = webStorageService.getSessionData('ErrorKey') ? webStorageService.getSessionData('ErrorKey') : translations.CoreKelloggs_GeneralErrorMsg_klg,
        ErrorCode = webStorageService.getSessionData('ErrorCode') ? webStorageService.getSessionData('ErrorCode') : '',
        titleError = ErrorKey,
        hideErrHead = (ErrorCode == 140) ? true : false;
    dataLayers().errorCodeEvent("kelloggs.com", ErrorCode);
    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_ErrorPageTitle + ' | ' + translations.CampaignName}
                description={translations.MetaContent}
                keywords=''
            />
            <section className="error-page">
                <h1>{translations.CoreKelloggs_ErrorPageHeading}</h1>
                <p role="alert" dangerouslySetInnerHTML={{ __html: titleError }}></p>
                <Button />
            </section>
        </>
    )
}

export default Error;