import * as webStorageService from './WebStorageFactory';
import { locale } from './Global';
import dataLayers from './DataLayers';
const moment = require('moment-timezone'); // UTC time according to the timezones npm i moment-timezone
const PromotionState = (settings, translations) => {

    function _initPage() {
        var path = window.location.pathname.toLowerCase(),
            isStartPage = (path.match('home') || path.match('\/' + locale + '\/$') || path.match('\/' + locale + '$') || path === '/') && !path.match('thankyou') ? true : false;
        dataLayers().init(settings);
        dataLayers().gtm(translations);
        if (isStartPage) {
            webStorageService.removeSessionData('servToken');
            webStorageService.removeLocalData('servToken');
            webStorageService.removeSessionData('grmUser');
            webStorageService.removeSessionData('lastUrlArr');
        }
        //Setting initial data in session storage
        // initConfig.setDefaultUrl(locale);//set default/start url for requested localization
        webStorageService.setSessionData('requestedLang', locale); //store requested localization in session storage
        // var initPermission = initConfig.getPagePermission(); //get start permissions for each page from init configuration service
        //Data Layers
        if (isStartPage) {
            // webStorageService.setSessionData('permission', initPermission); //store start permission in session storage 
            // dataLayers.init();
            // dataLayers.gtm();
        }
        if (path.match('recoverpassword') || path.match('forgotpassword')) {
            // webStorageService.setSessionData('permission', initPermission); //store start permission in session storage 
        }

        var fState = new _getStates();
        //check Promotion State status and redirect to valid page
        _checkAppState(fState.getPromotionState());
        // _checkAppState('holdingpage');
    };
    function _getStates() {
        if (locale != 'ch' && locale != 'be') {

            var startDate = moment(settings.StartDate),
                startDateTime = startDate.format(),
                
                endDate = moment(settings.EndDate),
                endDateTime = endDate.format(),

                currentDate = new Date(),
                currentDateUTC = moment(currentDate).tz(settings.TimeZone),
                currentTime = currentDateUTC.format(),

                outOfHrsEndDate = settings.OutOfHrsEndDate == null ? currentDateUTC : moment(settings.OutOfHrsEndDate),
                outOfHrsEndDateTime = outOfHrsEndDate.format(),

                extendedStartDate = settings.ExtendedStartDate == null ? currentDateUTC : moment(settings.ExtendedStartDate),
                extendedStartDateTime = extendedStartDate.format();
        }
        function _getPromotionState() {
            if (locale != 'ch' && locale != 'be') {
                if (currentTime >= startDateTime) {
                    if (currentTime > endDateTime)
                        return 'closed';
                    else if (currentTime < outOfHrsEndDateTime) {
                        if (currentDateUTC.hours() >= startDate.hours() && currentDateUTC.hours() <= outOfHrsEndDate.hours()) {
                            if (currentDateUTC.hours() == startDate.hours() && currentDateUTC.hours() == outOfHrsEndDate.hours()) {
                                if (currentDateUTC.minutes() >= outOfHrsEndDate.minutes()) {
                                    if (currentDateUTC.minutes() == outOfHrsEndDate.minutes()) {
                                        if (currentDateUTC.seconds() > outOfHrsEndDate.seconds()) {
                                            return 'outofhours';
                                        } else {
                                            return 'live';
                                        }
                                    } else {
                                        return 'outofhours';
                                    }

                                }
                            }
                            else if (currentDateUTC.hours() == startDate.hours()) {
                                if (currentDateUTC.minutes() <= startDate.minutes()) {
                                    if (currentDateUTC.minutes() == startDate.minutes()) {
                                        if (currentDateUTC.seconds() < startDate.seconds()) {
                                            return 'outofhours';
                                        } else {
                                            return 'live';
                                        }
                                    } else {
                                        return 'outofhours';
                                    }
                                }
                            } else if (currentDateUTC.hours() == outOfHrsEndDate.hours()) {
                                if (currentDateUTC.minutes() >= outOfHrsEndDate.minutes()) {
                                    if (currentDateUTC.minutes() == outOfHrsEndDate.minutes()) {
                                        if (currentDateUTC.seconds() > outOfHrsEndDate.seconds()) {
                                            return 'outofhours';
                                        } else {
                                            return 'live';
                                        }
                                    } else {
                                        return 'outofhours';
                                    }

                                }
                            }
                            return 'live';
                        }
                        else {
                            return 'outofhours'
                        }
                    }

                    return 'live';
                }
                return 'holdingpage';
            } else {
                return 'splitter';
            }
        }
        function _getExtendedState() {
            if (currentTime >= extendedStartDateTime && currentTime < endDateTime) {
                return true;
            }
            return false;
        }

        return {
            getPromotionState: _getPromotionState,
            getExtendedState: _getExtendedState,
        };
    };
    function _checkAppState(state) {
        if (!state) { return; }
        var url = '';
        switch (state.toLowerCase()) {
            case 'holdingpage':
                url = '/' + locale + '/Comingsoon';
                webStorageService.setSessionData('createPageState', 'Comingsoon');
                if ((window.location.href.toLowerCase()).indexOf('comingsoon') <= -1) {
                    window.location.href = url;
                }
                break;
            case 'closed':
                url = '/' + locale + '/Closed';
                webStorageService.setSessionData('createPageState', 'Closed');
                if ((window.location.href.toLowerCase()).indexOf('closed') <= -1) {
                    window.location.href = url;
                }
                break;
            case 'outofstock':
                url = '/' + locale + '/Outofstock';
                webStorageService.setSessionData('createPageState', 'Outofstock');
                break;
            case 'outofhours':
                webStorageService.setSessionData('createPageState', 'Outofhours');
                break;
            case 'live':
                break;
            case 'splitter':
                url = '/' + locale;
                break;
            default:
                url = '/' + locale + '/Error';
                webStorageService.setSessionData('createPageState', 'Error');
                window.location.href = url;
                break;
        }
    }
    return {
        initPage: _initPage,
        getStates: _getStates
    }

}
export default PromotionState;