import React, { useContext } from 'react';
import { Redirect } from "react-router-dom";
import { MetaTags, locale } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
import * as webStorageService from '../Utility/WebStorageFactory';
import Button from '../Partials/Button';
const Closed = () => {
    const { translations } = useContext(JsonContext);
    document.querySelector('body').setAttribute('pagename', 'Closed');
    let pageState = webStorageService.getSessionData('createPageState');
    // pageState = 'Closed';
    if (pageState == '' || pageState == undefined || pageState == null) {
        window.location = '/' + locale + '/Home';
    } else if (pageState != 'Closed') {
        return <Redirect to={'/' + locale + '/' + pageState} />
    }

    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_PromotionClosedPageTitle + ' | ' + translations.CampaignName }
                description={translations.MetaContent}
                keywords=''
            />
            <section className="closed-page comingsoon-page">
                <div className="content col-one">
                <h1>{translations.PromotionClosedHeading}</h1>
                    <p>{translations.PromotionClosedDescription}</p>
                    <p>{translations.PromotionClosedDescription1}</p>
                <p>{translations.PromotionClosedDescription2}</p>
                <a href={translations.CoreKelloggs_PromotionClosedCtaLink} className="button active" title={translations.CoreKelloggs_PromotionClosedCtaLinkTitle}>
                    <span className="btn-copy">{translations.CoreKelloggs_PromotionClosedCta}</span>
                </a>
                </div>
            </section>
        </>
    )
}

export default Closed;